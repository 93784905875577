import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor() { }
  public openQuotes = new BehaviorSubject<number>(0);

  setSearch(quotes : number){
     this.openQuotes.next(quotes);
  }
}
