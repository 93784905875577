import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { I18n } from 'src/app/i18n';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

const backgroundColorLine = '#FFFFFF';
const borderColorLine = '#FF7401';

@Component({
  selector: 'app-weekly-service-rate-card',
  templateUrl: './weekly-service-rate-card.component.html',
  styleUrls: ['./weekly-service-rate-card.component.scss']
})
export class WeeklyServiceRateCardComponent implements OnInit {

  constructor(
    private router: Router,
    private service: DataAnalyticsService,
    private t: I18n
  ) { }

  public chartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false
    },
    layout: {
      padding: {
        top: 35
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value, ctx) => {
          if(ctx!=null){
            if(ctx.datasetIndex===1){ // service rate
              return value+"%";
            } else if(ctx.datasetIndex===0){ // service rate target
              return "";
            }
          }
        },
        font: {
          size: 12,
        }
      }
    },
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: {
          display: true
        }
      }],
      yAxes: [{
        id: "y-axis",
        display: false,
        gridLines: {
          display: false
        },
        ticks: {
          beginAtZero: true,
          stepSize: 1
        }
      }]
    },
    maintainAspectRatio: false,
  };

  public chartType: ChartType = 'line';
  public chartLegend = true;
  public chartData: ChartDataSets[] = [];
  public chartLabels: String[] = []; //Devem ser listados como label todos os status que tenham pelo menos um pedido
  public barChartPlugins = [pluginDataLabels];
  public serviceRateTotal = 0;
  public serviceRateTarget: number = 0;
  public serviceRateValue: number = 0;
  public serviceRateTargetToCompare: number = 0;
  public signal: String = "";
  public plusSignal: Boolean = false;

  ngOnInit() {
    this.chartLabels = ['Sab', 'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex'];
    this.getLineChartData();
  }

  public getLineChartData() {

    this.service.getServiceRateWeekly().then(data => {
      this.serviceRateTarget = data.laboratory.serviceRateTarget;
      if(this.serviceRateTarget==null){
        this.serviceRateTarget=0;
      }

      this.chartLabels = data.positionData;  
      this.serviceRateTotal = data.serviceRateTotal;

      this.getSignal();

      this.chartData = [
        {
          data: [this.serviceRateTarget, this.serviceRateTarget, this.serviceRateTarget, this.serviceRateTarget, this.serviceRateTarget, this.serviceRateTarget, this.serviceRateTarget],
          type: 'line',
          backgroundColor: 'rgba(255,255,255,0)',
          borderColor: borderColorLine,
          lineTension:0.000001,
          borderWidth: 1,
          pointRadius: 0,
          pointBackgroundColor: backgroundColorLine,
          pointBorderColor: borderColorLine,
          pointHoverBackgroundColor: borderColorLine
      },{
        data: data.serviceRateOutByDay,
        lineTension:0.000001,
        backgroundColor: 'rgba(255,255,255,0)',
        pointBorderColor: ['rgb(111,168,220)', 'rgb(111,168,220)', 'rgb(111,168,220)', 'rgb(111,168,220)', 'rgb(111,168,220)', 'rgb(111,168,220)','rgb(111,150,220)']
      }];
    });
    
  }

  public getSignal() {
    this.serviceRateValue = this.serviceRateTotal;
    this.serviceRateTargetToCompare = this.serviceRateTarget;
    if(this.serviceRateValue >= this.serviceRateTargetToCompare){
      this.signal="+";
      this.plusSignal=true;
    } else {
      this.signal="-";
      this.plusSignal=false;
    }
  }

}
