import { LaboratoryDTO } from "./LaboratoryDTO";

export class LateOrderByPeriod  {
	laboratory: LaboratoryDTO;
	totalLateOrders: number;
	totalOrders: number;
	rateValue: number;
	numberOrdersLateOneTwoDays: number;
    numberOrdersLateThreeDays: number;
	numberOrdersLateFourFiveDays: number;
	numberOrdersLateSixTenDays: number;
	numberOrdersLateMoreTenDays: number;
}