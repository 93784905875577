
interface String {
    contains(item: string): boolean;
    replaceAll(from: string, to: string): string;
    equalsIgnoreCase(str: string): boolean;
    haveOtherCharsThan(str: string): boolean;
}

String.prototype.contains = function(item: string){
    return this.indexOf(item) > -1;
}
String.prototype.replaceAll = function(from: string, to: string){
    let str = this;
    while(str.contains(from)){
        str = str.replace(from, to);
    }
    return str;
}
String.prototype.equalsIgnoreCase = function(str: string): boolean{
    let t = this && this.toLowerCase ? this.toLowerCase() : this;
    let s = str && str.toLowerCase ? str.toLowerCase() : str;
    return t == s;
}

String.prototype.haveOtherCharsThan = function(str: string): boolean{
    let t: string[] = this.split('');
    let s = str.split('');
    return t.any(c => !s.contains(c));
}

interface StringConstructor {
    isNullOrEmpty: (str: string) => boolean;
} 

String.isNullOrEmpty = (str): boolean => {    
    return str === '' || str === null || str == undefined; 
}