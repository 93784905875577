import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { I18n } from 'src/app/i18n';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';

const blueLine = 'rgb(111,168,220)';
const orangeLine = '#FF7401';
const redLine = '#FF2E01';

@Component({
  selector: 'app-weekly-volume-detail',
  templateUrl: './weekly-volume-detail.component.html',
  styleUrls: ['./weekly-volume-detail.component.scss']
})
export class WeeklyVolumeDetailComponent implements OnInit {

  constructor(
    private router: Router,
    private service: DataAnalyticsService,
    private t: I18n
  ) { }

  public chartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 20
      }
    },
    tooltips: {
      enabled: false
    },
    layout: {
      padding: {
        //right: 50
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        id: "y-axis-1",
        display: true,
        position: "left",
        ticks: {
          max: 2200,
          min: 1000
        }
      },
      {
        id: "y-axis-2",
        display: false,
        ticks: {
          max: 2200,
          min: 1000
        }
      },
      {
        id: "y-axis-3",
        display: false,
        ticks: {
          max: 2200,
          min: 1000
        },
        gridLines: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        }
      }]
    },
    maintainAspectRatio: false,
  };

  public chartType: ChartType = 'line';
  public chartLegend = true;
  public chartData: ChartDataSets[] = [];
  public chartLabels: string[] = []; //Devem ser listados como label todos os status que tenham pelo menos um pedido

  ngOnInit() {
    this.chartLabels = ['Sab', 'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex'];
    this.getLineChartData();
  }

  public getLineChartData() {
    this.chartData = [{
      label: "In",
      borderColor: redLine,
      backgroundColor: redLine,
      fill: false,
      lineTension:0,	
      spanGaps:true,
      data: [1434, 1400 ,1672, 1289, 1872, 1677, 1890],
      yAxisID: "y-axis-1",
    },
    {
      label: "WIP",
      borderColor: blueLine,
      backgroundColor: blueLine,
      fill: false,
      lineTension:0,	
      spanGaps:true,
      data: [1872, 1900 , 1714, 1917, 2122, 1912, 1824],
      yAxisID: "y-axis-2"
  },
  {
      label: "Out",
      borderColor: orangeLine,
      backgroundColor: orangeLine,
      fill: false,
      lineTension:0,	
      spanGaps:true,
      data: [1200, 1300 ,1782, 1435, 1780, 1500, 1700],
      yAxisID: "y-axis-3"
    }];
  }

}
