import { Injectable } from '@angular/core';
import { ServiceHandler } from '../handlers/service.handler';
import { ApiRoute } from '../enums/api-route';
import { BreakReportItemDTO } from '../models/BreakReportItemDTO';
import { IntegrationErrorReportItemDTO } from '../models/IntegrationErrorReportItemDTO';
import { CalculationErrorReportItemDTO } from '../models/CalculationErrorReportItemDTO';
import { CertificateErrorReportItemDTO } from '../models/CertificateErrorReportItemDTO';
import { InProductionReportItemDTO } from '../models/InProductionReportItemDTO';

@Injectable()
export class ReportService {

    constructor(
        public service: ServiceHandler
    ) { }

    getBreakReport(startDate: string, endDate: string): Promise<BreakReportItemDTO[]>{
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.report.break.replace('{startDate}', startDate)
                                                    .replace('{endDate}', endDate))
                                                    .then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    getIntegrationErrorReport(startDate: string, endDate: string): Promise<IntegrationErrorReportItemDTO[]>{
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.report.integrationError.replace('{startDate}', startDate)
                                                    .replace('{endDate}', endDate))
                                                    .then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    getCalculationErrorReport(startDate: string, endDate: string): Promise<CalculationErrorReportItemDTO[]>{
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.report.calculationError.replace('{startDate}', startDate)
                                                    .replace('{endDate}', endDate))
                                                    .then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    getCertificateErrorReport(startDate: string, endDate: string): Promise<CertificateErrorReportItemDTO[]>{
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.report.certificateError.replace('{startDate}', startDate)
                                                    .replace('{endDate}', endDate))
                                                    .then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    getInProductionReport(startDate: string, endDate: string): Promise<InProductionReportItemDTO[]>{
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.report.inProduction.replace('{startDate}', startDate)
                                                    .replace('{endDate}', endDate))
                                                    .then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
        });
    }
}