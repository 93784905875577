import { LaboratoryDTO } from "./LaboratoryDTO";
import { OrderLenseQuantityDTO } from "./OrderLenseQuantityDTO";
import { OrderLenseServiceRateDTO } from "./OrderLenseServiceRateDTO";

export class ServiceRateDTO {

    laboratory: LaboratoryDTO;
    totalOrdersWIP: number;
    totalOrdersOUT: number;
    deliveredOrdersWIP: number;
    deliveredOrdersOUT: number;
    rateOrdersWIP: number;
    rateOrdersOut: number;
    lenseQuantityByType: OrderLenseQuantityDTO[];
	lenseQuantityByTypeOnTime: OrderLenseQuantityDTO[];
    lenseServiceRate: OrderLenseServiceRateDTO[];
} 
