import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType, Chart } from 'chart.js';
import { I18n } from 'src/app/i18n';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';
import { Router } from '@angular/router';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { LateOrderByPeriod } from 'src/app/models/LateOrderByPeriod';

const backgroundColorD1 = '#ffff4e';
const backgroundColorD2 = '#FFEC01';
const backgroundColorD3 = '#FFB201';
const backgroundColorD4 = '#FF7401';
const backgroundColorD5 = '#FF2E01';

const typeWIP = "WIP";
const typeOUT = "OUT";

@Component({
  selector: 'app-late-orders-out-card',
  templateUrl: './late-orders-out-card.component.html',
  styleUrls: ['./late-orders-out-card.component.scss']
})
export class LateOrdersOutCardComponent implements OnInit {

  constructor(
    private router: Router,
    private service: DataAnalyticsService,
    private t: I18n
  ) { }

  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    layout: {
      padding: {
        top: 30
      }
    },
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        stacked: true,
        display: false,
        gridLines: {
          display: false
        }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 12,
        }
      }
    },	
    maintainAspectRatio: false,
  };

  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartData: ChartDataSets[] = [];
  public barChartWIP: ChartDataSets[] = [];
  public barChartOut: ChartDataSets[] = [];
  public barChartLabels: string[] = []; //Devem ser listados como label todos os status que tenham pelo menos um pedido
  public type = "";
  public barChartPlugins = [pluginDataLabels];
  public lateOrdersByPeriodWIP: LateOrderByPeriod = new LateOrderByPeriod();
  public lateOrdersByPeriodOUT: LateOrderByPeriod = new LateOrderByPeriod();
  public totalOrders: number;
  public totalOrdersWIP: number;
  public totalOrdersOut: number;
  public totalLateOrders: number;
  public totalLateOrdersWIP: number;
  public totalLateOrdersOut: number;
  public rateValue: number;
  public rateValueWIP: number;
  public rateValueOut: number;
  public lateOrdersRateTarget: number = 0;
  public signal: String = "";
  public plusSignal: Boolean = false;
  public rateValueToCompare: number = 0;

  ngOnInit() {
    this.type = "WIP";
    this.barChartLabels = ['>2', '>3', '>5', '>10'];
    this.getBarChartData();
  }

  public getBarChartData() {

    this.service.listLateOrders(typeWIP).then(data => {
      this.lateOrdersByPeriodWIP = data;
      this.lateOrdersRateTarget = this.lateOrdersByPeriodWIP.laboratory.lateOrdersRateTarget;
      if(this.lateOrdersRateTarget==null){
        this.lateOrdersRateTarget=0;
      }
      this.totalLateOrdersWIP = this.lateOrdersByPeriodWIP.totalLateOrders;
      this.totalOrdersWIP = this.lateOrdersByPeriodWIP.totalOrders;
      this.rateValueWIP = this.lateOrdersByPeriodWIP.rateValue;
      this.barChartWIP = [{
        data: [this.lateOrdersByPeriodWIP.numberOrdersLateThreeDays, this.lateOrdersByPeriodWIP.numberOrdersLateFourFiveDays, 
                this.lateOrdersByPeriodWIP.numberOrdersLateSixTenDays, this.lateOrdersByPeriodWIP.numberOrdersLateMoreTenDays],
        backgroundColor: [backgroundColorD1, backgroundColorD2, backgroundColorD3, backgroundColorD4, backgroundColorD5]
      }]
      this.clickWIP();
    });

    this.service.listLateOrders(typeOUT).then(data => {
      this.lateOrdersByPeriodOUT = data;
      this.totalLateOrdersOut = this.lateOrdersByPeriodOUT.totalLateOrders;
      this.totalOrdersOut = this.lateOrdersByPeriodOUT.totalOrders;
      this.rateValueOut = this.lateOrdersByPeriodOUT.rateValue;
      this.barChartOut = [{
        data: [this.lateOrdersByPeriodOUT.numberOrdersLateThreeDays, this.lateOrdersByPeriodOUT.numberOrdersLateFourFiveDays, 
                this.lateOrdersByPeriodOUT.numberOrdersLateSixTenDays, this.lateOrdersByPeriodOUT.numberOrdersLateMoreTenDays],
        backgroundColor: [backgroundColorD1, backgroundColorD2, backgroundColorD3, backgroundColorD4, backgroundColorD5]
      }]
    });

  }

  public clickWIP() {
    this.type = "WIP";
    this.barChartData = this.barChartWIP;
    this.totalOrders = this.totalOrdersWIP;
    this.rateValue = this.rateValueWIP;
    this.totalLateOrders = this.totalLateOrdersWIP;
    this.getSignal();
  }

  public clickOut() {
    this.type = "OUT";
    this.barChartData = this.barChartOut;
    this.totalOrders = this.totalOrdersOut;
    this.rateValue = this.rateValueOut;
    this.totalLateOrders = this.totalLateOrdersOut;
    this.getSignal();
  }
  
  async goToPage(pageName:string){
    await this.router.navigate([`/main/${pageName}`]);
  }

  public getSignal() {
    this.rateValueToCompare = this.lateOrdersRateTarget;
    if(this.rateValue >= this.rateValueToCompare){
      this.signal="+";
      this.plusSignal=true;
    } else {
      this.signal="-";
      this.plusSignal=false;
    }
  }

}
