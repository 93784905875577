import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { I18n } from 'src/app/i18n';
import { OrderService } from 'src/app/services/order-service';
import { MatDialog } from '@angular/material/dialog';
import { OrderDTO } from 'src/app/models/OrderDTO';
import { ModalComponent } from 'src/app/components/shared/modal/modal.component';
import { JobTypeEnum } from 'src/app/enums/job-type';
import { SearchService } from 'src/app/services/search.service';
import { FeasibilityImageDTO } from 'src/app/models/FeasibilityImageDTO';
import { ModalFeasibilityComponent } from 'src/app/components/shared/modal-feasibility/modal-feasibility.component';
import { FeasibilityDTO } from 'src/app/models/FeasibilityDTO';

@Component({
  selector: 'app-orders-search',
  templateUrl: './orders-search.component.html',
  styleUrls: ['./orders-search.component.scss'] 
})
export class OrdersSearchComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private t: I18n,
    private service: OrderService,
    private orderSearch: SearchService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private feasibilityDialog: MatDialog

  ) {
    this.orderSearch.openQuotes.subscribe(
      (quotes) => {
        this.joborder =quotes;
this.searchJob();
      }
   );
   }
   joborder
  expandedElement: OrderDTO | null;

  orderListPaginated: OrderDTO[] = [];

  feasibilityDTO: FeasibilityDTO = null;

  orderList: OrderDTO[] = [];
  lengthLista = true;

  // color: string = "#F3C032";

  loadingOrderList: boolean = true;
  qtd: number;

  ngOnInit() {
    this.joborder = this.activatedRoute.snapshot.paramMap.get('search');
    this.searchJob();
  }

  searchJob(){
    this.service.getOrdersByERPJob(this.joborder).then(data=> {
      this.orderList = data['records'];
      this.lengthLista = this.orderList.length > 8 ? false : true;
      this.loadingOrderList = false;
    }).catch(error=>  {
    });
  }
  
  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => {});
  }

  getTypeEnum(enumCode: string){
    return JobTypeEnum[enumCode];
  }

  ItensPaginator(ev) {
    this.orderListPaginated = ev;
    this.loadingOrderList = false;
    this.cd.detectChanges();
  }

  openModal(id: string) {
    this.dialog.closeAll();
    const modal = this.dialog.open(ModalComponent, {
      data: {
        body: this.t.i18n.page.modalText.Certificado.title,
        textButtonRefused: this.t.i18n.page.modalText.Deleted.textButtonRefused,
        textButtonConfirmed: this.t.i18n.page.modalText.Deleted.textButtonConfirmed,
      }
    
    });
    modal.componentInstance.onAdd.subscribe(() => {
      this.service.getCertificateReprint(id);
    });
  }
  detalhe(env){
    this.router.navigate([`/main/request-detail/${env}`]);
  }

  feasibility(id: string) {
    this.openFeasibilityModal(id);
  }

  openFeasibilityModal(id:string) {
    this.feasibilityDialog.closeAll();
    const feasibilityModal = this.feasibilityDialog.open(ModalFeasibilityComponent, {
      data: {
        orderId: id
      }
    });
  }

  downloadOrderFile(erpJob:string) {
    this.service.generatePdfFile(erpJob).then(data=> {
    }).catch(error=>  {
      console.log("erro ao fazer download do pdf");
    });
  }

  openOrderFile(erpJob:string) {
    this.service.getPdfFile(erpJob).then(data=> {
      var url = window.URL.createObjectURL(data);

      var anchor = document.createElement('a');
      anchor.href = url;
      anchor.target = '_blank';

      anchor.click();
    }).catch(error=>  {
      console.log("erro ao abrir o pdf");
    });
  }
}
