import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { I18n } from 'src/app/i18n';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { AboveAverageStatusDTO } from 'src/app/models/AboveAverageStatusDTO';

@Component({
  selector: 'app-above-average-orders-card',
  templateUrl: './above-average-orders-card.component.html',
  styleUrls: ['./above-average-orders-card.component.scss']
})
export class AboveAverageOrdersCardComponent implements OnInit {

  hasData = false;

  constructor(
    private router: Router,
    private service: DataAnalyticsService,
    private t: I18n
  ) { }

  public chartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false
    },
    layout: {
      padding: {
        top: 20
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 12,
        }
      }
    },	
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        stacked: true,
        display: false,
        gridLines: {
          display: false
        }
      }]
    },
    maintainAspectRatio: false,
  };

  public chartType: ChartType = 'bar';
  public chartLegend = true;
  public chartData: ChartDataSets[] = [];
  public chartLabels: string[] = []; //Devem ser listados como label todos os status que tenham pelo menos um pedido
  public barChartPlugins = [pluginDataLabels];
  public aboveAverageStatus: AboveAverageStatusDTO[];

  ngOnInit() {
    this.hasData = false;
    this.chartLabels = [];
    this.getBarChartData();
  }

  public getBarChartData() {
    this.service.getAboveAverageData().then(data => {
      this.aboveAverageStatus = data;
      if (this.aboveAverageStatus.length>0){
        this.hasData = true;
      }
      for (let i = 0; i < this.aboveAverageStatus.length; i++) {
        this.chartLabels.push(this.aboveAverageStatus[i].percentage)
      }
      this.chartData = [{
        borderColor: 'rgb(255,153,0)',
        backgroundColor: ['rgb(255,194,0)', 'rgb(255,153,0)', 'rgb(255,100,0)', 'rgb(255,51,0)', 'rgb(255,15,0)'], 
        data: []
      }];
      for (let i = 0; i < this.aboveAverageStatus.length; i++) {
        let quantity = this.aboveAverageStatus[i].quantity;
        this.chartData[0].data.push(quantity);
      }
    });
  }

}
