import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { JobTypeEnum } from 'src/app/enums/job-type';
import { I18n } from 'src/app/i18n';
import { OrderDTO } from 'src/app/models/OrderDTO';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';

@Component({
  selector: 'app-above-average-orders-by-status',
  templateUrl: './above-average-orders-by-status.component.html',
  styleUrls: ['./above-average-orders-by-status.component.scss']
})
export class AboveAverageOrdersByStatusComponent implements OnInit {

  status: string;
  private sub: any;
  orderListPaginated: OrderDTO[] = [];
  orderList: OrderDTO[] = [];
  lengthLista = true;
  loadingOrderList: boolean = true;
  selectedFilters: string = "";

  constructor(private t: I18n,
              private route: ActivatedRoute,
              private router: Router,
              private service: DataAnalyticsService,
              private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.status = params['status'];
      this.selectedFilters = params['selectedFilters'];
      this.service.getAboveAverageStatusOrders(this.status, this.selectedFilters).then(data => {
        this.orderList = data['records'];
        this.lengthLista = this.orderList.length > 8 ? false : true;
        this.loadingOrderList = false;
      });
    });
  }

  getTypeEnum(enumCode: string){
    return JobTypeEnum[enumCode];
  }

  ItensPaginator(ev) {
    this.orderListPaginated = ev;
    this.loadingOrderList = false;
    this.cd.detectChanges();
  }

  detalhe(env){
    this.router.navigate([`/main/request-detail/${env}`]);
  }

  downloadOrderFile(erpJob:string) {
    this.service.generatePdfFile(erpJob).then(data=> {
    }).catch(error=>  {
      console.log("erro ao fazer download do pdf");
    });
  }

  openOrderFile(erpJob:string) {
    this.service.getPdfFile(erpJob).then(data=> {
      var url = window.URL.createObjectURL(data);

      var anchor = document.createElement('a');
      anchor.href = url;
      anchor.target = '_blank';

      anchor.click();
    }).catch(error=>  {
      console.log("erro ao abrir o pdf");
    });
  }
}
