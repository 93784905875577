import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../shared/modal/modal.component';
import { ToastsComponent } from '../shared/toasts/toasts.component';

import { I18n } from 'src/app/i18n';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-left-menu-order',
  templateUrl: './left-menu-order.component.html',
  styleUrls: ['./left-menu-order.component.scss']
})
export class LeftMenuOrderComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private t: I18n,
    private toast: MatSnackBar)
  { }

  ngOnInit() {
  }
  openDialog() {
    this.dialog.closeAll();
    const modal = this.dialog.open(ModalComponent, {
      data: {
        body:this.t.i18n.page.modalText.Confirmed.body,
        textButtonRefused:this.t.i18n.page.modalText.Confirmed.textButtonRefused,
        textButtonConfirmed:this.t.i18n.page.modalText.Confirmed.textButtonConfirmed, 
      }
    });

    const sub = modal.componentInstance.onAdd.subscribe(() => {

    });

  }

}
