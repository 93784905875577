import { Component, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  @Input() data: any = [];
  @Output() resposta = new EventEmitter();
  pageIndex = 0;
  pageSize = 8;
  lowValue = 0;
  highValue = 8;
  listRulesPaginated: any;
  constructor(
    private cd: ChangeDetectorRef,
  ) {}
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges) {
    this.data = changes.data.currentValue;
    this.paginatedItem(this.lowValue, this.highValue);
  }
   getPaginatorData(event) {
    if (event.pageIndex === this.pageIndex + 1) {
      this.lowValue = this.lowValue + this.pageSize;
      this.highValue = this.highValue + this.pageSize;
    } else if (event.pageIndex === this.pageIndex - 1) {
      this.lowValue = this.lowValue - this.pageSize;
      this.highValue = this.highValue - this.pageSize;
    }
    this.pageIndex = event.pageIndex;
    this.paginatedItem(this.lowValue, this.highValue);
  }
  paginatedItem(init: number, end: number) {
    this.listRulesPaginated = this.data.slice(init, end);
    this.envRes(this.listRulesPaginated);
  }
  envRes(ev) {
    this.cd.detectChanges();
    this.resposta.emit(ev);
  }
}
