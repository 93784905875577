import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { I18n } from 'src/app/i18n';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';

@Component({
  selector: 'app-volume-wip-detail',
  templateUrl: './volume-wip-detail.component.html',
  styleUrls: ['./volume-wip-detail.component.scss']
})
export class VolumeWipDetailComponent implements OnInit {

  loading = false;
  
  constructor(
    private service: DataAnalyticsService,
    private t: I18n
  ) { }

  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 20
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        id: "y-axis",
        display: true,
        position: "left",
        ticks: {
          beginAtZero: true,
          stepSize: 1
        }
      }],
    },
    maintainAspectRatio: false,
  };

  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartData: ChartDataSets[] = [];
  public barChartLabels: String[] = []; //Devem ser listados como label todos os status que tenham pelo menos um pedido
  public wipChartData: number[] = [];
  public inChartData: number[] = [];
  public outChartData: number[] = [];
  public startDate: Date;
  public endDate: Date;
  public datePicker: Date;
  public formattedStartDate: string;
  public formattedEndDate: string;
  public totalWip: number = 0;
  public totalIn: number = 0;
  public totalOut: number = 0;

  ngOnInit() {
    this.loading = true;
    this.barChartLabels = [];
    this.barChartData = []
    //this.getData();
  };

  public getData(){

    if(this.datePicker!=undefined){

      this.datePicker.setHours(0);
      this.datePicker.setMinutes(0);
      this.datePicker.setSeconds(0);

      const datepipe: DatePipe = new DatePipe('en-US');
      this.formattedStartDate = datepipe.transform(this.datePicker, 'yyyy-MM-dd HH:mm:ss');

      this.datePicker.setHours(23);
      this.datePicker.setMinutes(59);
      this.datePicker.setSeconds(59);

      this.formattedEndDate = datepipe.transform(this.datePicker, 'yyyy-MM-dd HH:mm:ss')

      this.service.listVolumeData(this.formattedStartDate, this.formattedEndDate).then(data => {
        this.inChartData = data.inData; // verify if empty
        this.wipChartData = data.wipData; // verify if empty
        this.outChartData = data.outData; // verify if empty
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.barChartLabels = data.positionData;
        this.totalWip = data.totalWip;
        this.totalIn = data.totalIn;
        this.totalOut = data.totalOut;
        this.getLineChartData();
        this.barChartLegend = true;
      });
    }

  }

  public getLineChartData() {
    this.barChartData = [{
      label: "In",
      borderColor: '#ff0000',
      backgroundColor: '#ff0000',
      fill: false,
      lineTension:0,	
      spanGaps:true,
      data: this.inChartData,
      yAxisID: "y-axis",	
      hidden: false,
    }, {
      label: "WIP",
      borderColor: '#0000FF',
      backgroundColor: '#0000FF',
      fill: false,
      lineTension:0,	
      spanGaps:true,
      data: this.wipChartData,
      yAxisID: "y-axis",
      hidden: true,
    }, {
      label: "Out",
      borderColor: '#FFA500',
      backgroundColor: '#FFA500',
      fill: false,
      lineTension:0,	
      spanGaps:true,
      data: this.outChartData,
      yAxisID: "y-axis",
      hidden: false,
    }]

    this.loading=false;
  }

}
