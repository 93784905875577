import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { I18n } from 'src/app/i18n';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { DatePipe } from '@angular/common';
import { ServiceRateWeeklyDetailedDTO } from 'src/app/models/ServiceRateWeeklyDetailedDTO';

const backgroundColorLine = '#FFFFFF';
const borderColorLine = '#FF7401';

@Component({
  selector: 'app-weekly-service-rate-detail',
  templateUrl: './weekly-service-rate-detail.component.html',
  styleUrls: ['./weekly-service-rate-detail.component.scss']
})
export class WeeklyServiceRateDetailComponent implements OnInit {

  constructor(
    private router: Router,
    private service: DataAnalyticsService,
    private t: I18n
  ) { }
  
  public chartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    layout: {
      padding: {
        top: 70
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value, ctx) => {
          if(ctx!=null){
            if(ctx.datasetIndex===1){ // service rate
              return value+"%";
            } else if(ctx.datasetIndex===0){ // service rate target
              return "";
            }
          }
        },
        font: {
          size: 12,
        }
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          autoSkip: false,
          maxRotation: 45,
          minRotation: 45
        },
      }],
      yAxes: [{
        id: "y-axis-1",
        display: true,
        position: "left",
        ticks: {
          callback: function(value, index, values) {
            return value + '%';
          }
        },
        gridLines: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      }]
    },
    maintainAspectRatio: false,
  };

  public chartType: ChartType = 'bar';
  public chartLegend = true;
  public chartData: ChartDataSets[] = [];
  public chartLabels: String[] = []; //Devem ser listados como label todos os status que tenham pelo menos um pedido
  public barChartPlugins = [pluginDataLabels];
  public startDatePicker: Date;
  public endDatePicker: Date;
  public clientCode: string = "";
  public responseMessage: String = "";
  public formattedStartDate: string = "";
  public formattedEndDate: string = "";
  public serviceRateWeeklyDetailedDTO: ServiceRateWeeklyDetailedDTO = new ServiceRateWeeklyDetailedDTO();
  public xAxisDates: String[] = [];
  public yAxisServiceRate: number[] = [];
  public serviceRateTarget: number = null;
  public serviceRateTotal: number = null;
  public targetAchieved: boolean = false;
  public serviceRateTargetArray: number[] = [];

  public clientCodeWithoutClientCode = 'WITHOUT_CLIENT_CODE';

  ngOnInit() {
    this.chartLabels = [];
  }

  search() {
    this.xAxisDates=[];
    this.yAxisServiceRate = [];

    if(this.startDatePicker==undefined || this.endDatePicker==undefined ||
        this.startDatePicker==null || this.endDatePicker==null ) {
      this.responseMessage="Os dois campos de data devem ser preenchidos";
      return;
    }
    else {
      let differenceInTime = this.endDatePicker.getTime() - this.startDatePicker.getTime();
      let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
      if(differenceInDays>30){
        this.responseMessage="O relatório deve possuir, no máximo, 30 dias entre a data de início e a data de fim";
        return;
      }
    }

    this.responseMessage="";

    const datepipe: DatePipe = new DatePipe('en-US');
    this.formattedStartDate = datepipe.transform(this.startDatePicker, 'yyyy-MM-dd');
    this.formattedEndDate = datepipe.transform(this.endDatePicker, 'yyyy-MM-dd');
    this.getLineChartData();
  }
  
  public getLineChartData() {

    let selectedClientCode = this.clientCodeWithoutClientCode;
    if(this.clientCode!=null && this.clientCode!=""){
      selectedClientCode = this.clientCode;
    }

    this.service.getDetailedServiceRateWeekly(this.formattedStartDate, this.formattedEndDate, selectedClientCode).then(data => {

      if(data==null) {
        return;
      }

      this.serviceRateWeeklyDetailedDTO = data;

      if(this.serviceRateWeeklyDetailedDTO.laboratory!=null && this.serviceRateWeeklyDetailedDTO.laboratory.serviceRateTarget!=null){
        this.serviceRateTarget=this.serviceRateWeeklyDetailedDTO.laboratory.serviceRateTarget;
      }
      if(this.serviceRateWeeklyDetailedDTO.serviceRateTotal!=null){
        this.serviceRateTotal=this.serviceRateWeeklyDetailedDTO.serviceRateTotal;
      }
      if(this.serviceRateTotal!=null && this.serviceRateTarget!=null){
        if(this.serviceRateTotal>=this.serviceRateTarget){
          this.targetAchieved=true;
        } else {
          this.targetAchieved=false;
        }
      }

      for(let i=0; i<this.serviceRateWeeklyDetailedDTO.serviceRateByDay.length; i++){
        let dateFormatted = this.getFormattedDate(this.serviceRateWeeklyDetailedDTO.serviceRateByDay[i].day);
        this.xAxisDates.push(dateFormatted);
        let serviceRate = this.serviceRateWeeklyDetailedDTO.serviceRateByDay[i].serviceRate;
        this.yAxisServiceRate.push(serviceRate);
        if(this.serviceRateTarget!=null){
          this.serviceRateTargetArray.push(this.serviceRateTarget);
        } else {
          this.serviceRateTargetArray.push(0);
        }
      }

      this.chartLabels = this.xAxisDates;  
      this.chartData = [{
        backgroundColor: 'rgba(255,255,255,0)',
        data: this.serviceRateTargetArray,
        fill: false,
        type: 'line',
        lineTension:0.000001,
        borderWidth: 1,
        pointRadius: 0,
        borderColor: borderColorLine,
        pointBackgroundColor: backgroundColorLine,
        pointBorderColor: borderColorLine,
        pointHoverBackgroundColor: borderColorLine
      },{
        borderColor: 'rgb(111,168,220)',
        backgroundColor: 'rgb(111,168,220)',
        data: this.yAxisServiceRate,
        fill: false,
        lineTension:0.000001,
        borderWidth: 5,
        spanGaps:true,
        pointBorderWidth: [5,5,5,5,5,5,5],
        pointBorderColor: ['rgb(111,168,220)', 'rgb(111,168,220)', 'rgb(111,168,220)', 'rgb(111,168,220)', 'rgb(111,168,220)', 'rgb(111,168,220)','rgb(111,150,220)'],
        type: 'line',
        yAxisID: "y-axis-1",	
      }]; 

    });

  }

  public getFormattedDate(date:Date) {
    let datepipe: DatePipe = new DatePipe('en-US');
    return datepipe.transform(date, 'dd/MM');
  }
}
