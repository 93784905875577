import { Component, Inject, Output, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { EventEmitter } from '@angular/core';
import { OrderService } from 'src/app/services/order-service';
import { FeasibilityDTO } from 'src/app/models/FeasibilityDTO';
import { I18n } from 'src/app/i18n';

@Component({
  selector: 'app-modal-feasibility',
  templateUrl: './modal-feasibility.component.html',
  styleUrls: ['./modal-feasibility.component.scss']
})
export class ModalFeasibilityComponent implements OnInit {

  loading = false;
  feasibilitySuccess=false;
  feasibilityDTO: FeasibilityDTO = null;

  constructor(private  dialogRef : MatDialogRef<ModalFeasibilityComponent>,
                        @Inject(MAT_DIALOG_DATA) public data: any,
                        private t: I18n,
                        private service: OrderService) { }

  ngOnInit() {
    this.loading = true;
    this.service.getFeasibilty(this.data.orderId).then(data=> {
      if(data!=null){
        this.feasibilityDTO = data;
      }
      if(this.feasibilityDTO.feasibilityImage!=null){
        this.feasibilitySuccess=true;
      }
      this.loading = false;
    }).catch(error=>  {
      this.loading = false;
    });
  }

}
