import { Component, OnInit, ChangeDetectorRef, ɵConsole } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { I18n } from 'src/app/i18n';
import { ConfigRuleDTO } from 'src/app/models/ConfigRuleDTO';
import { QueryBuilderService } from 'src/app/services/query-builder-service';
import { ConfigQueryBuilderType } from 'src/app/enums/config-querybuilder-type';
import { RouteConfig } from 'src/app/enums/route-config';
import { ModalComponent } from '../shared/modal/modal.component';
import { ToastsComponent } from '../shared/toasts/toasts.component';



@Component({
  selector: 'app-configuration-list',
  templateUrl: './configuration-list.component.html',
  styleUrls: ['./configuration-list.component.scss'],
})

export class ConfigurationListComponent implements OnInit {
  listRulesFull: ConfigRuleDTO[] = [];
  listRulesFiltered: ConfigRuleDTO[] = [];
  listRulesPaginated: ConfigRuleDTO[] = [];
  loading = true;
  name: string;
  description: string;
  username: string;

  searchName: string;
  lengthLista = true;
  // tslint:disable-next-line: max-line-length
  typesValues = Object.keys(this.t.i18n.page.mainConfiguration.fields.type).map(x => Reflect.get(this.t.i18n.page.mainConfiguration.fields.type, x));

  statusValues = [{ name: this.t.i18n.page.mainConfiguration.fields.state.option1, value: 0 },
  { name: this.t.i18n.page.mainConfiguration.fields.state.option2, value: "true" },
  { name: this.t.i18n.page.mainConfiguration.fields.state.option3, value: "false" }]

  types = new FormControl([0]);
  status = new FormControl([0]);
  configPath = RouteConfig.newConfig;
  constructor(
    private t: I18n,
    private qbService: QueryBuilderService,
    private route: Router,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private toast: MatSnackBar
  ) { }
  ngOnInit() {
    this.getAllDto();
  }
  getAllDto() {
    this.qbService.getRulesList()
      .then(data => {        
        this.listRulesFiltered = this.listRulesFull = data;
        this.lengthLista = this.listRulesFiltered.length > 8 ? false : true;
        this.loading = false;
      })
      .catch((err) => {
      });
  }
  getTypeEnum(enumCode: string) {
    return ConfigQueryBuilderType[enumCode];
  }
  edit(id: number) {
    this.route.navigate([RouteConfig.config, id]);
  }
  deletedItemModal(env) {
    this.dialog.closeAll();
    const modal = this.dialog.open(ModalComponent, {
      data: {
        body: this.t.i18n.page.modalText.Deleted.body + ` '${env.name.toUpperCase()}' ?`,
        textButtonRefused: this.t.i18n.page.modalText.Deleted.textButtonRefused,
        textButtonConfirmed: this.t.i18n.page.modalText.Deleted.textButtonConfirmed,
      }
    });
    modal.componentInstance.onAdd.subscribe(() => {
      this.deledtItem(env.id)
    });
  }
  deledtItem(env) {
    this.loading = true;
    this.qbService.deleteRule(env).then((res) => {
      setTimeout(() =>this.ngOnInit(), 500);
      this.openToast(this.t.i18n.page.toastsText.deletedSuccess);
    }).catch((err) => {
      this.openToast(this.t.i18n.page.toastsText.deletedError);
    });
  }
  ItensPaginator(ev) {
    this.listRulesPaginated = ev;
    this.loading = false;
    this.cd.detectChanges();
  }
  openToast(text: string) {
    this.toast.openFromComponent(ToastsComponent, {
      data: text
    });
  }


  onFilterChange(ev: any, filter: number) {

    if (filter == 0) {
      this.types = this.types.value.length == 0 || ev == 0 ? new FormControl([0]) : new FormControl(this.types.value.remove(0));
    } else {
      this.status = this.status.value.length == 0 || ev == 0 ? new FormControl([0]) : new FormControl(this.status.value.remove(0));
    }

    this.filter();
  }

  search() {
    setTimeout(() => this.filter(), 500);
  }

  filter() {
    this.listRulesFiltered = this.listRulesFull.filter(rule => {
      return (this.types.value.includes(0) || this.types.value.includes(rule.type))
        && (this.status.value.includes(0) || this.status.value.includes(rule.active.toString()))
        && (!this.searchName || rule.name.toLowerCase().contains(this.searchName.toLowerCase()))

    });
    this.lengthLista = this.listRulesFiltered.length > 8 ? false : true;
  }
  clear() {
    this.searchName = '';
    this.status = new FormControl([0]);
    this.types = new FormControl([0]);
    this.filter();
  }
}


