export const RouteConfig = {
    login: 'login',
    home: '',
    user: 'users',
    config: 'main/configuration',
    newConfig: 'main/edit-configuration',
    orderDashboard: {
        main: 'main/orderDashboard',
        integration: 'main/orderDashboard/integration',
        calculationError: 'main/orderDashboard/calculationError',
        manualCalculation: 'main/orderDashboard/manualCalculation',
    },
    certificate:'main/certificate',
    laboratory:'main/laboratory',
    releaseorder:'main/releaseorder',
    reportbreaks:'main/reportbreaks'
}

