export class ConfigRuleDTO {

    id?: string;
    name: string;
    type: string;
    description: string;
    active: boolean = true;
    jsQuery: string;
    username: string;
    modified: Date; 
    associated:boolean = false;
} 