import { Injectable, ɵConsole } from '@angular/core';
import { LocaleType } from './LocaleType';
import { StorageHandler } from '../handlers/storage-handler';
import { Table, Language } from '../enums';
import { Locale as Locale_PTBR } from './PTBR';
import { Locale as Locale_ENUS } from './ENUS';

@Injectable()

export class I18n {
  i18n: LocaleType;
  options = [Locale_PTBR, Locale_ENUS];

  constructor(public Storage: StorageHandler) {
    let locs = StorageHandler.List(Table.i18n);
    let code = this.getBrowserCode();
    if (locs.length > 0) { 
      code = locs[0].code; 
    }
    this.i18n = this.options.filter(o => { return o.code == code })[0];
    StorageHandler.Save(Table.i18n, { code: code });
  }

  changeLanguage(code?: Language): Language {
    if(code == undefined){
      code = this.i18n.code == Language.pt ? Language.en : Language.pt;
    }
    let selected = this.options.filter(o => { return o.code == code });
    if (selected.length > 0) {
      this.i18n = selected[0];
      StorageHandler.DeleteTable(Table.i18n);
      StorageHandler.Save(Table.i18n, { code: code });
      return code;  
    }
  }

  getLanguage(){
    return StorageHandler.List(Table.i18n)[0].code;
  }
  getBrowserCode() {
    return window.navigator.language.contains('pt') ? Locale_PTBR.code : Locale_ENUS.code;
  }
}
