import { LaboratoryDTO } from "./LaboratoryDTO";
import { ServiceRateWeeklyPointDTO } from "./ServiceRateWeeklyPointDTO";

export class ServiceRateWeeklyDetailedDTO {
    laboratory: LaboratoryDTO;
    serviceRateByDay: ServiceRateWeeklyPointDTO[];
    startDate: Date;
    endDate: Date;
    serviceRateTotal: number;
} 

