import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { I18n } from 'src/app/i18n';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

const borderColor = 'rgb(255, 99, 132)';
const backgroundColor = 'rgb(111,168,220)';
const typeIN = "IN";
const typeWIP = "WIP";
const typeOUT = "OUT";

@Component({
  selector: 'app-volume-wip-card',
  templateUrl: './volume-wip-card.component.html',
  styleUrls: ['./volume-wip-card.component.scss']
})
export class VolumeWipCardComponent implements OnInit {
  
  constructor(
    private router: Router,
    private service: DataAnalyticsService,
    private t: I18n
  ) { }

  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    layout: {
      padding: {
        left: 20,
        right: 30
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 12,
        }
      }
    },
    scales: {
      xAxes: [{
        stacked: true,
        display: false,
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        stacked: true,
        display: true,
        gridLines: {
          display: true
        }
      }]
    },
    maintainAspectRatio: false,
  };

  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = false;
  public barChartData: ChartDataSets[] = [];
  public barChartDataWIP: ChartDataSets[] = [];
  public barChartDataIn: ChartDataSets[] = [];
  public barChartDataOut: ChartDataSets[] = [];
  public barChartLabels: string[] = []; //Devem ser listados como label todos os status que tenham pelo menos um pedido
  public total = 0;
  public totalWIP = 0;
  public totalIn = 0;
  public totalOut = 0;
  public type = "";
  public barChartPlugins = [pluginDataLabels];

  ngOnInit() {
    this.barChartLabels = ['SD', 'ST', 'AR', 'LP', 'MO']; //labels: ['Digital', 'Tradicional', 'AR', 'Lente Pronta', 'Montagem'],
    this.barChartData = []
    this.barChartDataWIP = []
    this.barChartDataIn = []
    this.barChartDataOut = []
    this.total = 0;
    this.totalWIP = 0;
    this.totalIn = 0;
    this.totalOut = 0;
    this.type = "WIP";
    this.getBarChartData();
  };

  public getBarChartData() {

    this.service.listLenseQuantityByType(typeIN).then(data => {
      let chartData = [0,0,0,0,0];
      data.forEach(element => {
        if(element.jobTypeInitials.equalsIgnoreCase("SD")){
          chartData[0] = element.quantity;
        } else if(element.jobTypeInitials.equalsIgnoreCase("ST")){
          chartData[1] = element.quantity;
        } else if(element.jobTypeInitials.equalsIgnoreCase("AR")){
          chartData[2] = element.quantity;
        } else if(element.jobTypeInitials.equalsIgnoreCase("FL")){
          chartData[3] = element.quantity;
        } else if(element.jobTypeInitials.equalsIgnoreCase("MO")){
          chartData[4] = element.quantity;
        }
        this.totalIn+=element.quantity;
      });
      this.barChartDataIn = [
        { data: chartData, backgroundColor: backgroundColor, borderColor: borderColor }
      ]
    });

    this.service.listLenseQuantityByType(typeWIP).then(data => {
      let chartData = [0,0,0,0,0];
      data.forEach(element => {
        if(element.jobTypeInitials.equalsIgnoreCase("SD")){
          chartData[0] = element.quantity;
        } else if(element.jobTypeInitials.equalsIgnoreCase("ST")){
          chartData[1] = element.quantity;
        } else if(element.jobTypeInitials.equalsIgnoreCase("AR")){
          chartData[2] = element.quantity;
        } else if(element.jobTypeInitials.equalsIgnoreCase("FL")){
          chartData[3] = element.quantity;
        } else if(element.jobTypeInitials.equalsIgnoreCase("MO")){
          chartData[4] = element.quantity;
        }
        this.totalWIP+=element.quantity;
      });
      this.barChartDataWIP = [
        { data: chartData, backgroundColor: backgroundColor, borderColor: borderColor }
      ]
      this.clickWIP();
    });

    this.service.listLenseQuantityByType(typeOUT).then(data => {
      let chartData = [0,0,0,0,0];
      data.forEach(element => {
        if(element.jobTypeInitials.equalsIgnoreCase("SD")){
          chartData[0] = element.quantity;
        } else if(element.jobTypeInitials.equalsIgnoreCase("ST")){
          chartData[1] = element.quantity;
        } else if(element.jobTypeInitials.equalsIgnoreCase("AR")){
          chartData[2] = element.quantity;
        } else if(element.jobTypeInitials.equalsIgnoreCase("FL")){
          chartData[3] = element.quantity;
        } else if(element.jobTypeInitials.equalsIgnoreCase("MO")){
          chartData[4] = element.quantity;
        }
        this.totalOut+=element.quantity;
      });
      this.barChartDataOut = [
        { data: chartData, backgroundColor: backgroundColor, borderColor: borderColor }
      ]
    });
    
  };

  public clickWIP() {
    this.barChartData = this.barChartDataWIP;
    this.total = this.totalWIP;
    this.type = typeWIP;
  }

  public clickIn() {
    this.barChartData = this.barChartDataIn;
    this.total = this.totalIn;
    this.type = typeIN;
  }

  public clickOut() {
    this.barChartData = this.barChartDataOut;
    this.total = this.totalOut;
    this.type = typeOUT;
  }

  async goToPage(pageName:string){
    await this.router.navigate([`/main/${pageName}`]);
  }
}


