import { Injectable } from '@angular/core';
import { ServiceHandler } from '../handlers/service.handler';
import { ApiRoute } from '../enums/api-route';
import { QueryBuilderConfig } from 'angular2-query-builder';
import { ConfigRuleDTO } from '../models/ConfigRuleDTO';
import { CellSaveDTO } from '../models/CellSaveDTO';


@Injectable()
export class QueryBuilderService {

    constructor(
        public service: ServiceHandler
    ) { }

    configFields(): Promise<QueryBuilderConfig> {
        return new Promise((resolve, reject) => {            
                this.service.Get(ApiRoute.queryBuilder.config).then(data => {
                    resolve(data);
                }).catch(err => {
                    reject(err);
                });
            
        });
    }

    saveRule(rule: ConfigRuleDTO): Promise<JSON> {
        return new Promise((resolve, reject) => {            
                this.service.Post(ApiRoute.queryBuilder.save, rule).then(data => {
                    resolve(data);
                }).catch(err => {
                    reject(err);
                });
            
        });
    }
    getRulesList(): Promise<ConfigRuleDTO[]> {
        return new Promise<ConfigRuleDTO[]>((resolve, reject) => {            
                this.service.Get(ApiRoute.queryBuilder.listRules).then(data => {
                    resolve(data.rules);
                }).catch(err => {
                    reject(err);
                });
            
        });
    }

    getRule(id: string): Promise<ConfigRuleDTO> {
        return new Promise((resolve, reject) => {            
                this.service.Get(ApiRoute.queryBuilder.getRule.replace('{id}', id) ).then(data => {
                    resolve(data);
                }).catch(err => {
                    reject(err);
                });
            
        });
    }
        
    deleteRule(id:string): Promise<ConfigRuleDTO[]> {
        return new Promise((resolve, reject) => {            
                this.service.Delete(ApiRoute.queryBuilder.delete.replace("{id}",id)).then(data => {
                    resolve(data);
                }).catch(err => {
                    reject(err);
                });
             
        });
    }

    getRulesListByCellByStatus(id:string,status:string): Promise<ConfigRuleDTO[]> {
        return new Promise<ConfigRuleDTO[]>((resolve, reject) => {            
                this.service.Get(ApiRoute.queryBuilder.getRulesByCell
                    .replace("{id}",id)
                    .replace("{status}",status)).then(data => {
                    resolve(data.rules);
                }).catch(err => {
                    reject(err);
                });
            
        });
    }


    
    saveCellRules(id:string, list:CellSaveDTO): Promise<string> {
        return new Promise<string>((resolve, reject) => {            
                this.service.Post(ApiRoute.queryBuilder.saveCellRules
                    .replace("{id}",id), list).then(data => {
                    resolve(data);
                }).catch(err => {
                    reject(err);
                });
            
        });
    }
}