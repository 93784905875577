import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import * as Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
 
  constructor(
    private router: Router,
    private keycloakService: KeycloakService
    ) { }
permission={
prod: this.keycloakService.isUserInRole("HAS_ACCESS_CELL_DASHBOARD"),
orders:  this.keycloakService.isUserInRole("HAS_ACCESS_ORDERS")
};
  ngOnInit() {
    Chart.plugins.unregister(ChartDataLabels);
  }

  async goToPage(pageName:string){
    await this.router.navigate([`/main/${pageName}`]);
  }
}
