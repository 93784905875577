import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { I18n } from 'src/app/i18n';
import { ReworkStatusDTO } from 'src/app/models/ReworkStatusDTO';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-rework-detail',
  templateUrl: './rework-detail.component.html',
  styleUrls: ['./rework-detail.component.scss']
})
export class ReworkDetailComponent implements OnInit {

  hasData = false;

  constructor(
    private router: Router,
    private service: DataAnalyticsService,
    private t: I18n
  ) { }

  public chartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function(value, context) {
          let hours = Math.floor(Number(value.xLabel)/60);
          let minutes = Number(value.xLabel) % 60;
          let minutesTxt = "";
          minutesTxt = "" + minutes;
          if(minutes<10){
            minutesTxt = "0" + minutes;
          }
          let time = hours + "h " + minutesTxt + "min";
          return time;
        }
      }
    },
    layout: {
      padding: {
        //right: 50
      }
    },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'center',
        formatter: (value, ctx) => {
          let hours = Math.floor(value / 60);  
          let minutes = value % 60;
          let minutesTxt = "";
          minutesTxt = "" + minutes;
          if(minutes<10){
            minutesTxt = "0" + minutes;
          }
          let time = hours + ":" + minutesTxt;
          return time;
        },
        font: {
          size: 12,
        }
      }
    },
    scales: {
      xAxes: [{
        stacked: true,
        display: false,
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        stacked: true,
        gridLines: {
          display: false
        }
      }]
    },
    maintainAspectRatio: false,
  };

  public chartType: ChartType = 'horizontalBar';
  public chartLegend = true;
  public chartData: ChartDataSets[] = [];
  public chartLabels: string[] = []; //Devem ser listados como label todos os status que tenham pelo menos um pedido
  public reworkStatusData: ReworkStatusDTO[];
  public barChartPlugins = [pluginDataLabels];

  ngOnInit() {
    this.hasData = false;
    this.chartLabels = [];
    this.getBarChartData();
  }

  public getBarChartData() {
    this.service.getDetailedReworkStatus().then(data => {
      this.reworkStatusData = data;
      if (this.reworkStatusData.length>0){
        this.hasData = true;
      }
      for (let i = 0; i < this.reworkStatusData.length; i++) {
        this.chartLabels.push(this.reworkStatusData[i].statusName)
      }
      this.chartData = [{
        borderColor: 'rgb(255,153,0)',
        backgroundColor: 'rgb(255,153,0)',
        data: []
      }];
      for (let i = 0; i < this.reworkStatusData.length; i++) {
        let reworkMinutes = this.reworkStatusData[i].minutes;
        this.chartData[0].data.push(reworkMinutes);
      }
    });
  }

}
