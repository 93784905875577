import { Pipe, PipeTransform } from '@angular/core';
import { I18n } from '../i18n';

@Pipe({
  name: 'direction'
})
export class DirectionPipe implements PipeTransform {
  constructor(private t: I18n){

  }
    transform(value: any): any {
      switch (value) {
        case 'R': return this.t.i18n.page.Request.right;
          break;
        case 'L': return this.t.i18n.page.Request.left;
          break;
        default: return this.t.i18n.page.Request.both;
          break;
      }
    }
  
  }


