import { Pipe, PipeTransform } from '@angular/core';
import { I18n } from '../i18n';

@Pipe({
  name: 'transformType'
})
export class TransformTypePipe implements PipeTransform {
  constructor(private t: I18n) {

  }
  transform(value: any ): any {
    switch (value) {
      case 1: return this.t.i18n.page.PipeType.production;
        break;
      case 2: return this.t.i18n.page.PipeType.calculation;
        break;
      default: return this.t.i18n.page.PipeType.others;
        break;
    }

  }

}
