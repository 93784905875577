import { 
    OrderStatusEnum
} from "../../enums";



let orderStatusEnum = {};
orderStatusEnum[OrderStatusEnum.ARRIVED_LOH] = 'Chegou no LOH';
orderStatusEnum[OrderStatusEnum.SENT_OPTIFACTS] = 'Enviado ao Optifacts';
orderStatusEnum[OrderStatusEnum.VALIDATED_SERVICES_PRODUCTS_CLIENT] = 'Validados Produto, Serviço,Cliente ';
orderStatusEnum[OrderStatusEnum.VALIDATED_STRUCTURE] = 'Validada estrutura';

export const EnumLocale = {
    OrderStatusEnum: orderStatusEnum
}