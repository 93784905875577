import { Pipe, PipeTransform } from '@angular/core';
import { I18n } from '../i18n';

@Pipe({
  name: 'actived'
})
export class ActivedPipe implements PipeTransform {
  constructor(private t: I18n) {

  }

  transform(value: any): any {
    switch (value) {
      case true: return this.t.i18n.page.PipeActived.true
        break;
      default: return this.t.i18n.page.PipeActived.false
        break;
    }

  }

}
