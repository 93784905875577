import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManagerialService {

  constructor() { }
  public openQuotes = new BehaviorSubject<number>(0);

  setOpenQuote(quotes : number){
     this.openQuotes.next(this.openQuotes.value + 1);
  }
}
