import { Component, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ManagementStatusDTO } from 'src/app/models/ManagementStatusDTO';
import { MatDialog } from '@angular/material/dialog';
import { I18n } from 'src/app/i18n';
import { StatusManagementService } from 'src/app/services/status-management-service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastsComponent } from 'src/app/components/shared/toasts/toasts.component';
import { ModalComponent } from 'src/app/components/shared/modal/modal.component';
import { Location } from '@angular/common';
import { ManagerialService } from 'src/app/services/managerial.service';
import { SectorStatusDTO } from 'src/app/models/SectorStatusDTO';


@Component({
  selector: 'app-managerial-configuration',
  templateUrl: './managerial-configuration.component.html',
  styleUrls: ['./managerial-configuration.component.scss']
})
export class ManagerialConfigurationComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  loading = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  res: ManagementStatusDTO[] = [];
  filters: SectorStatusDTO[] = [];
  allData: ManagementStatusDTO[] = [];

  constructor(private dialog: MatDialog,
    private t: I18n,
    private service: StatusManagementService,
    private router: Router,
    private toast: MatSnackBar,
    private mana: ManagerialService,
    private location: Location) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.res = [];
    this.filters = [];
    this.allData = [];

    let firstFilterItem = new SectorStatusDTO();
    firstFilterItem.id=0;
    firstFilterItem.name="Todos";
    this.filters.push(firstFilterItem);
    let secondFilterItem = new SectorStatusDTO();
    secondFilterItem.id=-1;
    secondFilterItem.name="SEM SETOR";
    this.filters.push(secondFilterItem);

    this.service.getSectors().then(data => {
      data.forEach(sector => {
          this.filters.push(sector);
      });
    });

    this.service.getAll().then(data => {
      this.loading =false;
      this.res = data;
      this.allData = data;
    });
  }

  add(event: MatChipInputEvent, id): void {
    const input = event.input;
    const value = event.value;
    this.res.map((item) => {
      if ((value || '').trim() && item.id == id) {
        item.values.push(value.trim());
      }
    })
    if (input) {
      input.value = '';
    }
  }

  remove(value, id): void {
    this.res.map((item) => {
      let index = item.values.indexOf(value);
      if (index >= 0 && item.id == id) {
        item.values.splice(index, 1)
      }
    })
  }

  saveModal() {
    this.dialog.closeAll();
    const modal = this.dialog.open(ModalComponent, {
      data: {
        body: this.t.i18n.page.modalText.Confirmed.body,
        textButtonRefused: this.t.i18n.page.modalText.Deleted.textButtonRefused,
        textButtonConfirmed: this.t.i18n.page.modalText.Deleted.textButtonConfirmed,
      }
    });
    modal.componentInstance.onAdd.subscribe(() => {
      this.saveAll(this.allData);
      this.loadData();
    });
  }

  saveAll(env) {
    this.loading = true;
    this.service.saveAll(env).then((res) => {
      this.loading = false;
      this.ngOnInit();
      this.mana.setOpenQuote(1); 
      this.openToast(this.t.i18n.page.toastsText.saveConfirmation);
    }).catch((err) => {
      this.openToast(this.t.i18n.page.toastsText.saveError);
    });
  }

  openToast(text: string) {
    this.toast.openFromComponent(ToastsComponent, {
      data: text
    });
  }

  back() {
    this.dialog.closeAll();
    const modal = this.dialog.open(ModalComponent, {
      data: {
        body: this.t.i18n.page.modalText.YesNo.body,
        textButtonRefused: this.t.i18n.page.modalText.YesNo.textButtonRefused,
        textButtonConfirmed: this.t.i18n.page.modalText.YesNo.textButtonConfirmed,
      }
    });
    modal.componentInstance.onAdd.subscribe(() => {
      this.location.back();
    });
  }

  onFilterChange(sectorId: any) {
    console.log(sectorId);
    if(sectorId==0){ // todos
      this.res=this.allData;
    } else if(sectorId==-1){ // status sem setor
      this.res = this.allData.filter(p => p.sectorStatusId==null);
    } else {
      this.res = this.allData.filter(p => p.sectorStatusId==sectorId);
    }
  }
}
