import { Pipe, PipeTransform } from '@angular/core';
import { I18n } from '../i18n';

@Pipe({
  name: 'pedidosCelula'
})
export class PedidosCelulaPipe implements PipeTransform {
constructor(private t: I18n){

}
  transform(value: any): any {
    switch (value) {
      case 'CALCULATED': return this.t.i18n.page.visualizarPedidos.aba1;
        break;
      case 'IN_PRODUCTION': return this.t.i18n.page.visualizarPedidos.aba2;
        break;
      case 'CONCLUDED': return this.t.i18n.page.visualizarPedidos.aba3;
        break;
      case 'BROKEN': return this.t.i18n.page.visualizarPedidos.aba4;
        break;
      default: return this.t.i18n.page.visualizarPedidos.aba4;
        break;
    }
  }

}
