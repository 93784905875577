import { Component, OnInit } from '@angular/core';
import { ModalProducaoComponent } from 'src/app/components/shared/modal-producao/modal-producao.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CellLaboratoryDashboardDTO } from 'src/app/models/CellLaboratoryDashboardDTO';
import { CellLaboratoryService } from 'src/app/services/cellLaboratory-service';
import { I18n } from 'src/app/i18n';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastsComponent } from 'src/app/components/shared/toasts/toasts.component';


@Component({
  selector: 'app-laboratory',
  templateUrl: './laboratory.component.html',
  styleUrls: ['./laboratory.component.scss']
})
export class LaboratoryComponent implements OnInit {
  animation = false;
  interval: any;

  constructor(
    private dialog: MatDialog,
    private t: I18n,
    private service: CellLaboratoryService,
    private router: Router,
    private toast: MatSnackBar) { }

  language = this.t.getLanguage();

  labsDashboard: CellLaboratoryDashboardDTO[] = [];

  atualizado = new Date();
  quantidadealiberar: number;
  ngOnInit() {
    this.getData();
    this.atualizarautomtico();
  }
  atualizarautomtico() {
    this.interval = setInterval(() => {
      this.getData();

    }, 300000);
  }
  private getData() {
    this.atualizado = new Date();
    this.animation = true;
    this.service.getDashboardByLabid().then(data => {
      this.labsDashboard = [];
      setTimeout(() => {
        this.animation = false;
      },500);
      this.labsDashboard.push(data);
    });
  }

  atualizarnow() {
    this.getData();
  }
  openModal(id: string) {
    this.dialog.closeAll();
    const modal = this.dialog.open(ModalProducaoComponent, {
      data: { number: this.quantidadealiberar, id: id },
      panelClass: 'production-modalbox'

    });
    modal.afterClosed().subscribe((env) => {
      if(env !== undefined){
      if (!env.err ) {

        this.quantidadealiberar = env.number;
        this.openToast();
        this.atualizarnow();
      }else{
        this.errortoast();
      }
    }
    });
  }

  filters(id) {
    this.router.navigate([`main/celula/${id}`]);
  }
  errortoast(){
    this.toast.openFromComponent(ToastsComponent, {
      data: this.t.i18n.page.toastsText.errorPedido
    });
  }
  openToast() {
    this.toast.openFromComponent(ToastsComponent, {
      data: this.t.i18n.page.toastsText.liberar+" "+ this.quantidadealiberar
    });
  }
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
