import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { I18n } from 'src/app/i18n';
import { ServiceRateDetailedDTO } from 'src/app/models/ServiceRateDetailedDTO';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';

const color1 = '#ff3300';
const color2 = '#ff5c00';
const color3 = '#ff7000';
const color4 = '#ff9900';
const color5 = '#ffc200';
const color6 = '#ffff00';
const color7 = '#cceb00';
const color8 = '#8cd100';
const color9 = '#66c200';
const color10 = '#40b200';
const borderColor = 'rgb(255, 99, 132)';
const typeWIP = "WIP";
const typeOUT = "OUT";

@Component({
  selector: 'app-service-rate-out-detail',
  templateUrl: './service-rate-out-detail.component.html',
  styleUrls: ['./service-rate-out-detail.component.scss']
})
export class ServiceRateOutDetailComponent implements OnInit {

  constructor(
    private router: Router,
    private service: DataAnalyticsService,
    private t: I18n
  ) { }

  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
      labels: {
        boxWidth: 20
      }
    },
    tooltips: {
      mode: 'index',
      intersect: false
    },
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        stacked: true,
        gridLines: {
          display: false
        }
      }]
    },
    maintainAspectRatio: false,
  };

  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = false;
  public barChartData: ChartDataSets[] = [];
  public barChartWIP: ChartDataSets[] = [];
  public barChartOut: ChartDataSets[] = [];
  public barChartLabels: string[] = ['Sufaçagem Digital', 'Surfaçagem Tradicional', 'AR', 'Lente Pronta', 'Montagem']; //Devem ser listados como label todos os status que tenham pelo menos um pedido
  public serviceRateDetailed: ServiceRateDetailedDTO = new ServiceRateDetailedDTO();

  ngOnInit() {
    this.barChartLabels = ['SD', 'ST', 'AR', 'LP', 'MO'];
    this.getChartData();
  }
  
  public getChartData() {

    this.service.getDetailedServiceRate(typeWIP).then(data => {
      
      this.serviceRateDetailed = data;

      this.barChartWIP = [{
        //Para cada status teremos os seguintes datasets
          
          //Pedidos cujo dia de hoje seja 5 ou mais dias maior do que a data combinada
          label: '>= D+5',
                backgroundColor: color1,
                hoverBackgroundColor: color1,
                borderColor: borderColor,
                data: this.serviceRateDetailed.todayPlusFive
        }, {
          //Pedidos cujo dia de hoje seja 4 dias maior do que a data combinada
          label: 'D+4',
                backgroundColor: color2,
                hoverBackgroundColor: color2,
                borderColor: borderColor,	            
          data: this.serviceRateDetailed.todayPlusFour
        }, {
          //Pedidos cujo dia de hoje seja 3 dias maior do que a data combinada
          label: 'D+3',
                backgroundColor: color3,
                hoverBackgroundColor: color3,
                borderColor: borderColor,
                data: this.serviceRateDetailed.todayPlusThree
        }, {
          //Pedidos cujo dia de hoje seja 1 dias maior do que a data combinada
          label: 'D+2',
                backgroundColor: color4,
                hoverBackgroundColor: color4,
                borderColor: borderColor,
                data: this.serviceRateDetailed.todayPlusTwo
        }, {
          //Pedidos cujo dia de hoje seja 1 dia maior do que a data combinada			
          label: 'D+1',
                backgroundColor: color5,
                hoverBackgroundColor: color5,
                borderColor: borderColor,
                data: this.serviceRateDetailed.todayPlusOne
        }, {
          //Pedidos cujo dia de hoje seja igual à data combinada
          label: 'D+0',
                backgroundColor: color6,
                hoverBackgroundColor: color6,
                borderColor: borderColor,
                data: this.serviceRateDetailed.today
        }, {
          //Pedidos cujo dia de hoje seja 1 dia menor do que a data combinada			
          label: 'D-1',
                backgroundColor: color7,
                hoverBackgroundColor: color7,
                borderColor: borderColor,
                data: this.serviceRateDetailed.todayMinusOne
        }, {
          //Pedidos cujo dia de hoje seja 2 dias menor do que a data combinada			
          label: 'D-2',
                backgroundColor: color8,
                hoverBackgroundColor: color8,
                borderColor: borderColor,
                data: this.serviceRateDetailed.todayMinusTwo
        }, {
          //Pedidos cujo dia de hoje seja 3 dias menor do que a data combinada
          label: 'D-3',
                backgroundColor: color9,
                hoverBackgroundColor: color9,
                borderColor: borderColor,
                data: this.serviceRateDetailed.todayMinusThree
        }, {
          //Pedidos cujo dia de hoje seja 4 ou mais dias menor do que a data combinada
          label: '<= D-4',
                backgroundColor: color10,
                hoverBackgroundColor: color10,
                borderColor: borderColor,
                data: this.serviceRateDetailed.todayMinusFour
        }];

        this.clickWIP();
    })

    this.service.getDetailedServiceRate(typeOUT).then(data => {
    
      this.serviceRateDetailed = data;

      this.barChartOut = [{
        //Para cada status teremos os seguintes datasets
          
          //Pedidos cujo dia de hoje seja 5 ou mais dias maior do que a data combinada
          label: '>= D+5',
                backgroundColor: color1,
                hoverBackgroundColor: color1,
                borderColor: borderColor,
                data: this.serviceRateDetailed.todayPlusFive
        }, {
          //Pedidos cujo dia de hoje seja 4 dias maior do que a data combinada
          label: 'D+4',
                backgroundColor: color2,
                hoverBackgroundColor: color2,
                borderColor: borderColor,	            
          data: this.serviceRateDetailed.todayPlusFour
        }, {
          //Pedidos cujo dia de hoje seja 3 dias maior do que a data combinada
          label: 'D+3',
                backgroundColor: color3,
                hoverBackgroundColor: color3,
                borderColor: borderColor,
                data: this.serviceRateDetailed.todayPlusThree
        }, {
          //Pedidos cujo dia de hoje seja 1 dias maior do que a data combinada
          label: 'D+2',
                backgroundColor: color4,
                hoverBackgroundColor: color4,
                borderColor: borderColor,
                data: this.serviceRateDetailed.todayPlusTwo
        }, {
          //Pedidos cujo dia de hoje seja 1 dia maior do que a data combinada			
          label: 'D+1',
                backgroundColor: color5,
                hoverBackgroundColor: color5,
                borderColor: borderColor,
                data: this.serviceRateDetailed.todayPlusOne
        }, {
          //Pedidos cujo dia de hoje seja igual à data combinada
          label: 'D+0',
                backgroundColor: color6,
                hoverBackgroundColor: color6,
                borderColor: borderColor,
                data: this.serviceRateDetailed.today
        }, {
          //Pedidos cujo dia de hoje seja 1 dia menor do que a data combinada			
          label: 'D-1',
                backgroundColor: color7,
                hoverBackgroundColor: color7,
                borderColor: borderColor,
                data: this.serviceRateDetailed.todayMinusOne
        }, {
          //Pedidos cujo dia de hoje seja 2 dias menor do que a data combinada			
          label: 'D-2',
                backgroundColor: color8,
                hoverBackgroundColor: color8,
                borderColor: borderColor,
                data: this.serviceRateDetailed.todayMinusTwo
        }, {
          //Pedidos cujo dia de hoje seja 3 dias menor do que a data combinada
          label: 'D-3',
                backgroundColor: color9,
                hoverBackgroundColor: color9,
                borderColor: borderColor,
                data: this.serviceRateDetailed.todayMinusThree
        }, {
          //Pedidos cujo dia de hoje seja 4 ou mais dias menor do que a data combinada
          label: '<= D-4',
                backgroundColor: color10,
                hoverBackgroundColor: color10,
                borderColor: borderColor,
                data: this.serviceRateDetailed.todayMinusFour
        }];

    });
  }

  public clickWIP() {
    this.barChartData = this.barChartWIP;
  }

  public clickOut() {
    this.barChartData = this.barChartOut;
  }

}
