import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n';
import { OrderReleaseDTO } from 'src/app/models/OrderReleaseDTO';
import { CellLaboratoryService } from 'src/app/services/cellLaboratory-service';
import { OrderService } from 'src/app/services/order-service';

@Component({
  selector: 'app-release-order',
  templateUrl: './release-order.component.html',
  styleUrls: ['./release-order.component.scss']
})
export class ReleaseOrderComponent implements OnInit {
  
  erpjob: string = "";
  responseMessage: String = "";
  orderRelease: OrderReleaseDTO = new OrderReleaseDTO();

  constructor(
    private t: I18n,
    private service: CellLaboratoryService,
  ) { }

  ngOnInit() {
  }

  releaseOrder() {
    console.log("releaseOrder");
    if(this.erpjob==null || this.erpjob.equalsIgnoreCase("")){
      this.responseMessage="É preciso informar um número do pedido";
      this.erpjob = "";
      return;
    }
    this.service.releaseOrderByErpJob(this.erpjob).then(data=> {
      this.orderRelease = data;
      console.log(this.orderRelease);
      if(this.orderRelease!=null){
        if(this.orderRelease.errorList!=null && this.orderRelease.errorList.length>0){
          this.responseMessage=this.orderRelease.errorList[0].message;
        }
        if(this.orderRelease.releasedJobs!=null && this.orderRelease.releasedJobs.length>0){
          this.responseMessage="O pedido " + this.orderRelease.releasedJobs[0] + " foi liberado com sucesso." ;
        }
      }
      this.erpjob = "";
    }).catch(error=>  {
      console.log(error);
      this.responseMessage="Erro ao liberar pedidos";
      this.erpjob = "";
    });
  }

  onKeydown(e){
    if(e.key === "Enter"){
      this.releaseOrder();
    }
  }

}
