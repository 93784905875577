import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { I18n } from 'src/app/i18n';
import { StatusManagementService } from 'src/app/services/status-management-service';
import { ManagerialService } from 'src/app/services/managerial.service';
import { KeycloakService } from 'keycloak-angular';
import { SearchService } from 'src/app/services/search.service';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  showListPedidos: boolean = false;
  menuSelected: any;
  subMenuSelected: any;
  before: boolean;
  hasSettings: boolean;

  searchName:any;
  constructor(
    private route: Router,
    private t: I18n,
    private service: StatusManagementService,
    private mana: ManagerialService,
    private orderSearch: SearchService,
    private keycloakService: KeycloakService,

  ) {
    this.mana.openQuotes.subscribe(
      (quotes) => {
       this.getManagerial();
       this.informemenu()

      }
   );
  }


  menus = [
    { title: this.t.i18n.page.menu.home, show:true, link: '/main/home', },
    { title: this.t.i18n.page.menu.dashboard, show:this.keycloakService.isUserInRole("HAS_ACCESS_CELL_DASHBOARD"),  link: '/main/dashboard', },
    { title: this.t.i18n.page.menu.producao, show:this.keycloakService.isUserInRole("HAS_ACCESS_CELL_DASHBOARD"),  link: '/main/laboratory', },
    {
      title: this.t.i18n.page.menu.pedidos, link: '#',  show:this.keycloakService.isUserInRole("HAS_ACCESS_ORDERS"), sub: [
        { tip: {color:'#8C32F3' }, title: this.t.i18n.page.menu.integracao, link: '/main/orderDashboard/integration' },
        { tip: {color:'#FF4500' }, title: this.t.i18n.page.menu.calculo, link: '/main/orderDashboard/calculationError' },
        { tip: {color:'#F3C032' }, title: this.t.i18n.page.menu.analise, link: '/main/orderDashboard/manualCalculation' },
        { tip: {color:'#ff126e' }, title: this.t.i18n.page.menu.certificado, link: '/main/orderDashboard/errorCertificate' }
      ]
    },
    {
      title: this.t.i18n.page.menu.configuracao, link: '#', show:this.keycloakService.isUserInRole("HAS_ACCESS_CONFIGURATIONS"), sub: [
        {tip: {color:'#054f77'}, title: this.t.i18n.page.menu.filtros, link: '/main/configuration'},
        {tip: {color:'#054f77'}, title: this.t.i18n.page.menu.gerencial, link: '/main/managerial-configuration'}
      ]
    },
    { title: this.t.i18n.page.menu.imprimirCertificado, show:this.keycloakService.isUserInRole("HAS_ACCESS_CELL_DASHBOARD"),  link: '/main/certificate', },
    { title: this.t.i18n.page.menu.releaseOrder, show:this.keycloakService.isUserInRole("HAS_ACCESS_ORDER_RELEASE"),  link: '/main/releaseorder', },
    {
      title: this.t.i18n.page.menu.relatorios, link: '#',  show:this.keycloakService.isUserInRole("HAS_ACCESS_ORDERS"), sub: [
        { tip: {color:'#8C32F3' }, title: this.t.i18n.page.menu.quebras, link: '/main/reportbreaks' },
        { tip: {color:'#FF4500' }, title: this.t.i18n.page.menu.extracoes, link: '/main/extractions' }
      ]
    },
  ];
  SettingsKebab = [
    {title: this.t.i18n.page.menu.filtros, link: '/main/configuration'},
    {title: this.t.i18n.page.menu.gerencial, link: '/main/managerial-configuration'}
  ];
  AccountKebab = [
    {title: this.t.i18n.page.menu.logout, link: 'logout'}
  ];


  doLogout(){
    this.keycloakService.logout();
  }
  ngOnInit() {
    this.hasSettings = this.keycloakService.isUserInRole("HAS_ACCESS_CONFIGURATIONS");
  }
  getManagerial(){
    this.before = false;
    if (this.keycloakService.isUserInRole("HAS_ACCESS_CONFIGURATIONS") ){
      this.service.getAll().then(data => {
        data.map((mana)=>{
          if (!mana.values.length) {
            this.before = true
            this.informemenu()

          }
        })
      });
    }

  }
  submenus(ev) {


    this.showListPedidos = !this.showListPedidos;

  }

  rotas(menu,sub, ev) {
    ev.stopPropagation();
    let display = $('.' + menu.title).css('display');
    let flag = display == 'none' ? 'block' : 'none';

    $('ul.' + menu.title).css('display', flag);
    this.searchName = '';

   this.informemenu()
    if (menu.link != '#') {
      this.route.navigate([menu.link]);
      if(sub){
        this.subMenuSelected = menu;
      }else{
        this.menuSelected = menu;
        this.subMenuSelected = undefined;
      }
    } else {
      let e = $('#' + menu.title).hasClass('open');
      this.subMenuSelected = undefined;
      this.menuSelected = menu;
      if(flag=='block'){
        $('#' + menu.title).children('div').children('.fa-chevron-down').removeClass('closemenu');
        $('#' + menu.title).children('div').children('.fa-chevron-down').addClass('open');
      }else{
        $('#' + menu.title).children('div').children('.fa-chevron-down').removeClass('open');
        $('#' + menu.title).children('div').children('.fa-chevron-down').addClass('closemenu');
      }
    }

  }
  informemenu(){
    this.searchName = '';
    if (this.before) {
      $("."+this.SettingsKebab[1].title.replace(' ', '.')).addClass('before')
    }else{
      $("."+this.SettingsKebab[1].title.replace(' ', '.')).removeClass('before')
    }
  }
  search() {
    if (this.searchName) {
      this.orderSearch.setSearch(this.searchName);
      this.route.navigate(['main/order-search/'+this.searchName]);
    }

  }

  onKeydown(e){
    if(e.key === "Enter"){
       this.search();
    }
 }

  async goToPage(pageName:string){
    await this.route.navigate([`/main/${pageName}`]);
  }
}
