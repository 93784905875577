import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { I18n } from 'src/app/i18n';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { LaboratoryDTO } from 'src/app/models/LaboratoryDTO';
import { ServiceRateDTO } from 'src/app/models/ServiceRateDTO';

const backgroundColorD1 = 'rgb(111,168,220)';
const typeWIP = "WIP";
const typeOUT = "OUT";
const backgroundColorLine = '#FFFFFF';
const borderColorLine = '#FF7401';

@Component({
  selector: 'app-service-rate-out-card',
  templateUrl: './service-rate-out-card.component.html',
  styleUrls: ['./service-rate-out-card.component.scss']
})
export class ServiceRateOutCardComponent implements OnInit {

  constructor(
    private router: Router,
    private service: DataAnalyticsService,
    private t: I18n
  ) { }

  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    layout: {
      padding: {
        top: 30
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value, ctx) => {
          return value+"%";
        },
        font: {
          size: 12,
        }
      }
    },
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        stacked: true,
        display: false,
        gridLines: {
          display: false
        }
      }]
    },
    maintainAspectRatio: false,
  };

  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartData: ChartDataSets[] = [];
  public barChartWIP: ChartDataSets[] = [];
  public barChartOut: ChartDataSets[] = [];
  public barChartLabels: string[] = []; //Devem ser listados como label todos os status que tenham pelo menos um pedido
  public type = "";
  public barChartPlugins = [pluginDataLabels];
  public laboratory: LaboratoryDTO = new LaboratoryDTO();
  public serviceRate: ServiceRateDTO = new ServiceRateDTO();
  public serviceRateTarget: number = 0;
  public serviceRateTargetToCompare: number = 0;
  public totalOrders: Number = 0;
  public totalOrdersWIP: Number = 0;
  public totalOrdersOut: Number = 0;
  public serviceRateValue: Number = 0;
  public serviceRateValueWIP: Number = 0;
  public serviceRateValueOut: Number = 0;
  public signal: String = "";
  public plusSignal: Boolean = false;

  ngOnInit() {
    this.barChartLabels = ['SD', 'ST', 'AR', 'LP', 'MO'];
    this.type = "WIP";
    this.barChartData = [];
    this.barChartWIP = [];
    this.barChartOut = [];
    this.getLineChartData();
  }

  public getLineChartData() {

    this.service.getServiceRate(typeWIP).then(data => {
      this.serviceRate = data;
      this.serviceRateTarget = this.serviceRate.laboratory.serviceRateTarget;
      if(this.serviceRateTarget==null){
        this.serviceRateTarget=0;
      }
      this.totalOrdersWIP = this.serviceRate.totalOrdersWIP;
      this.serviceRateValueWIP = this.serviceRate.rateOrdersWIP;

      let chartDataWIP = [0,0,0,0,0];
      this.serviceRate.lenseServiceRate.forEach(element => {
        if(element.jobTypeInitials.equalsIgnoreCase("SD")){
          chartDataWIP[0] = element.jobTypeServiceRate;
        } else if(element.jobTypeInitials.equalsIgnoreCase("ST")){
          chartDataWIP[1] = element.jobTypeServiceRate;
        } else if(element.jobTypeInitials.equalsIgnoreCase("AR")){
          chartDataWIP[2] = element.jobTypeServiceRate;
        } else if(element.jobTypeInitials.equalsIgnoreCase("FL")){
          chartDataWIP[3] = element.jobTypeServiceRate;
        } else if(element.jobTypeInitials.equalsIgnoreCase("MO")){
          chartDataWIP[4] = element.jobTypeServiceRate;
        }
      });

      this.barChartWIP = [
        {
          data: [this.serviceRateTarget, this.serviceRateTarget, this.serviceRateTarget, this.serviceRateTarget, this.serviceRateTarget, this.serviceRateTarget, this.serviceRateTarget],
          label: 'Meta',
          type: 'line',
          backgroundColor: backgroundColorLine,
          borderColor: borderColorLine
        },
        {
            data: chartDataWIP,
            backgroundColor: [backgroundColorD1, backgroundColorD1, backgroundColorD1, backgroundColorD1, backgroundColorD1]
        }];

        this.clickWIP();
    });


    this.service.getServiceRate(typeOUT).then(data => {

      this.serviceRate = data;
      this.serviceRateTarget = this.serviceRate.laboratory.serviceRateTarget;
      if(this.serviceRateTarget==null){
        this.serviceRateTarget=0;
      }

      this.totalOrdersOut = this.serviceRate.totalOrdersOUT;
      this.serviceRateValueOut = this.serviceRate.rateOrdersOut;

      let chartDataOUT = [0,0,0,0,0];
      this.serviceRate.lenseServiceRate.forEach(element => {
        if(element.jobTypeInitials.equalsIgnoreCase("SD")){
          chartDataOUT[0] = element.jobTypeServiceRate;
        } else if(element.jobTypeInitials.equalsIgnoreCase("ST")){
          chartDataOUT[1] = element.jobTypeServiceRate;
        } else if(element.jobTypeInitials.equalsIgnoreCase("AR")){
          chartDataOUT[2] = element.jobTypeServiceRate;
        } else if(element.jobTypeInitials.equalsIgnoreCase("FL")){
          chartDataOUT[3] = element.jobTypeServiceRate;
        } else if(element.jobTypeInitials.equalsIgnoreCase("MO")){
          chartDataOUT[4] = element.jobTypeServiceRate;
        }
      });

      this.barChartOut = [
        {
          data: [this.serviceRateTarget, this.serviceRateTarget, this.serviceRateTarget, this.serviceRateTarget, this.serviceRateTarget, this.serviceRateTarget, this.serviceRateTarget],
          label: 'Meta',
          type: 'line',
          backgroundColor: backgroundColorLine,
          borderColor: borderColorLine
        },
        {
            data: chartDataOUT,
            backgroundColor: [backgroundColorD1, backgroundColorD1, backgroundColorD1, backgroundColorD1, backgroundColorD1]
        }];

    });
    
  }

  public clickWIP() {
    this.barChartData = this.barChartWIP;
    this.totalOrders = this.totalOrdersWIP;
    this.serviceRateValue = this.serviceRateValueWIP;
    this.serviceRateTargetToCompare = this.serviceRateTarget;
    if(this.serviceRateValue >= this.serviceRateTargetToCompare){
      this.signal="+";
      this.plusSignal=true;
    } else {
      this.signal="-";
      this.plusSignal=false;
    }
    this.type = "WIP";
  }

  public clickOut() {
    this.barChartData = this.barChartOut;
    this.totalOrders = this.totalOrdersOut;
    this.serviceRateValue = this.serviceRateValueOut;
    this.serviceRateTargetToCompare = this.serviceRateTarget;
    if(this.serviceRateValue >= this.serviceRateTargetToCompare){
      this.signal="+";
      this.plusSignal=true;
    } else {
      this.signal="-";
      this.plusSignal=false;
    }
    this.type = "OUT";
  }
  
  async goToPage(pageName:string){
    await this.router.navigate([`/main/${pageName}`]);
  }
}
