import { Component, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { QueryBuilderConfig, QueryBuilderClassNames } from 'angular2-query-builder';
import { I18n } from 'src/app/i18n';
import { ConfigRuleDTO } from 'src/app/models/ConfigRuleDTO';
import { QueryBuilderService } from 'src/app/services/query-builder-service';
import { ModalComponent } from 'src/app/components/shared/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastsComponent } from 'src/app/components/shared/toasts/toasts.component';
import { RouteConfig } from 'src/app/enums/route-config';
import { DOMManipulation } from 'src/app/utils/dom-manipulation';

@Component({
  selector: 'app-edit-configuration',
  templateUrl: './edit-configuration.component.html',
  styleUrls: ['./edit-configuration.component.scss']
})
export class EditConfigurationComponent {

  description = new FormControl('', [Validators.required]);
  nameForm = new FormControl('', [Validators.required]);


  configurationRuleDto: ConfigRuleDTO = new ConfigRuleDTO();
  isEdit = false;
  queryObri = false;
  isLoading = true;
  queryCtrl: FormControl;
  usernamesMock: string[] = ['Rachel', 'Guilherme', 'Andre', 'Alceno', 'Gabriel', 'Jonathan'];
  name: string = this.t.i18n.page.configuration.filterName;
  bootstrapClassNames: QueryBuilderClassNames = {
    removeIcon: 'fa fa-minus',
    addIcon: 'fa fa-plus',
    button: 'btn',
    buttonGroup: 'btn-group',
    rightAlign: 'order-12 ml-auto',
    switchRow: 'd-flex px-2',
    switchGroup: 'd-flex align-items-center',
    switchRadio: 'custom-control-input',
    switchLabel: 'custom-control-label',
    switchControl: 'custom-control custom-radio custom-control-inline',
    row: 'row p-2 m-1',
    rule: 'border',
    ruleSet: 'border',
    invalidRuleSet: 'alert alert-danger',
    emptyWarning: 'text-danger mx-auto',
    operatorControl: 'form-control',
    operatorControlSize: 'col-auto pr-0',
    fieldControl: 'form-control',
    fieldControlSize: 'col-auto pr-0',
    entityControl: 'form-control',
    entityControlSize: 'col-auto pr-0',
    inputControl: 'form-control',
    inputControlSize: 'col-auto'
  };

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewChecked() {

    DOMManipulation.changeAll('label', 'AND', this.t.i18n.page.configuration.queryBuilder.and);

    DOMManipulation.changeAll('label', 'OR', this.t.i18n.page.configuration.queryBuilder.or);

    DOMManipulation.changeAll('button', 'Rule', this.t.i18n.page.configuration.queryBuilder.rule);
    DOMManipulation.changeAll('button', 'Ruleset', this.t.i18n.page.configuration.queryBuilder.ruleset);

    DOMManipulation.changeAll('option', 'contains', this.t.i18n.page.configuration.queryBuilder.contains);
    DOMManipulation.changeAll('option', 'like', this.t.i18n.page.configuration.queryBuilder.like);

    DOMManipulation.changeAll('option', 'not in', this.t.i18n.page.configuration.queryBuilder.notIn);
    DOMManipulation.changeAll('option', 'in', this.t.i18n.page.configuration.queryBuilder.in);

    DOMManipulation.changeByClass('.text-danger.mx-auto.ng-star-inserted', this.t.i18n.page.configuration.queryBuilder.warning)
  }

  currentConfig: QueryBuilderConfig;

  constructor(
    private formBuilder: FormBuilder,
    private t: I18n,
    private qbService: QueryBuilderService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toast: MatSnackBar
  ) {
    const ruleId = this.activatedRoute.snapshot.paramMap.get('id');

    this.qbService.configFields().then(data => {
      this.currentConfig = data;

      this.qbService.getRule(ruleId || '').then(rule => {
        this.configurationRuleDto = rule;
        this.queryCtrl = this.formBuilder.control(this.configurationRuleDto.jsQuery);

        this.isLoading = false;
        this.configurationRuleDto.type = this.configurationRuleDto.type.toString();


      }).catch((err) => {
        this.isLoading = false;
      });
    }
    );

  }


  alterarNome() {
    this.isEdit = !this.isEdit;
    this.nameForm.markAsTouched();
  }
  validacao() {
    const teste = this.queryCtrl.value.rules;
    let res = false;

    if (teste.length > 0) {
      res = this.validador(teste);
    }

    this.queryObri = !res;
    if (res && this.description.status === "VALID" && this.nameForm.status === "VALID") {
      this.confirmDialog();
    } else {
      this.description.markAsTouched();
      this.nameForm.markAsTouched();
      this.isEdit = true;
    }
  }
  validador(res) {

    res = this.testemap(res);

    if (res.resposta == 2) {
      return false;
    } else if (res.resposta == 1) {
      return true;
    } else {
      return this.validador(res[0].resposta.rules);
    }
  }

  testemap(env) {
    let resposta: any;
    let array = [];
    env.map((item) => {
      if (item.value || item.value === 0 )  {
        resposta = { resposta: 1 }
      } else if (!item.value && item.rules) {
        array.push({ resposta: item });
      }
      else {
        resposta = { resposta: 2 };
      }
    })
    if (array.length > 0 && resposta.resposta != 2) {
      return array;

    } else {
      return resposta;
    }
  }

  confirmDialog() {
    this.dialog.closeAll();
    const modal = this.dialog.open(ModalComponent, {
      data: {
        body: this.t.i18n.page.modalText.Confirmed.body,
        textButtonRefused: this.t.i18n.page.modalText.Confirmed.textButtonRefused,
        textButtonConfirmed: this.t.i18n.page.modalText.Confirmed.textButtonConfirmed,
      }      
    });


    modal.componentInstance.onAdd.subscribe(() => {
      this.isLoading = true;
      this.configurationRuleDto.username = this.usernamesMock[Math.floor(Math.random() * 5)];
      this.qbService.saveRule(this.configurationRuleDto).then(() => {
        this.isLoading = false;
        this.openToast();

        this.router.navigate([`/main/configuration`]);
      });

    });

  }

  cancelDialog() {
    this.dialog.closeAll();
    const modal = this.dialog.open(ModalComponent, {
      data: {
        body: this.t.i18n.page.modalText.YesNo.body,
        textButtonRefused: this.t.i18n.page.modalText.YesNo.textButtonRefused,
        textButtonConfirmed: this.t.i18n.page.modalText.YesNo.textButtonConfirmed,
      }
    });
    modal.componentInstance.onAdd.subscribe(() => {
      this.router.navigate([RouteConfig.config]);
    });
  }
  openToast() {
    this.toast.openFromComponent(ToastsComponent, {
      data: this.t.i18n.page.toastsText.saveConfirmation
    });
  }


}
