import { Pipe, PipeTransform } from '@angular/core';
import { I18n } from '../i18n';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {
  constructor(private t: I18n){

  }
  transform(value: any): any {
    switch (value) {
      case 10: return this.t.i18n.page.status.concluded; // concluido
        break;
      case 11: return this.t.i18n.page.status.canceled; // cancelado
        break;
      case 12: return this.t.i18n.page.status.waiting; // aguardando armação
        break;
     default: return this.t.i18n.page.status.progress; // andamento
        break;
    }
  }

}
