import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-left-menu-configuration',
  templateUrl: './left-menu-configuration.component.html',
  styleUrls: ['./left-menu-configuration.component.scss']
})
export class LeftMenuConfigurationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
