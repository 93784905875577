export class VolumeDataDTO {
    wipData: number[];
    inData: number[];
    outData: number[];
    startDate: Date;
    endDate: Date;
    positionData: String[]; // hour or week day - axis x position
    totalWip: number;
	totalIn: number;
	totalOut: number;
} 

