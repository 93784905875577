import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { RouteConfig } from 'src/app/enums/route-config';
import { I18n } from 'src/app/i18n';
import { AboveAverageStatusDetailByStatusDTO } from 'src/app/models/AboveAverageStatusDetailByStatusDTO';
import { OrderDTO } from 'src/app/models/OrderDTO';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';

const color1 = '#ff3300';
const color2 = '#ff5c00';
const color3 = '#ff7000';
const color4 = '#ff9900';
const borderColor = 'rgb(255, 99, 132)';

@Component({
  selector: 'app-above-average-orders-detail',
  templateUrl: './above-average-orders-detail.component.html',
  styleUrls: ['./above-average-orders-detail.component.scss']
})
export class AboveAverageOrdersDetailComponent implements OnInit {

  hasData = false;

  constructor(
    private router: Router,
    private service: DataAnalyticsService,
    private t: I18n
  ) { }

  public chartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    tooltips: {
      mode: 'index',
      intersect: false
    },
    layout: {
      padding: {
        //top: 50
      }
    },
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        stacked: true,
        gridLines: {
          display: false
        }
      }]
    },
    maintainAspectRatio: false,
  };

  public chartType: ChartType = 'horizontalBar';
  public chartLegend = true;
  public chartData: ChartDataSets[] = [];
  public chartLabels: string[] = []; //Devem ser listados como label todos os status que tenham pelo menos um pedido
  public total: number;
  public aboveAverageStatusDetail: AboveAverageStatusDetailByStatusDTO;
  public selectedFilters: string[] = [];

  ngOnInit() {
    this.hasData=false;
    this.chartLabels = [];
    this.getBarChartData();
  }

  public getBarChartData() {
    this.service.getDetailedAboveAverageData().then(data => {
      this.aboveAverageStatusDetail = data;

      if(this.aboveAverageStatusDetail!=null){
        this.hasData=true;

        this.chartLabels = this.aboveAverageStatusDetail.status;

        this.chartData = [{
          label: 'Acima de 100%',
                backgroundColor: color1,
                hoverBackgroundColor: color1,
                borderColor: borderColor,
                data: this.aboveAverageStatusDetail.aboveAverageStatusDetail100				
        }, {
          label: 'Acima de 75%',
                backgroundColor: color2,
                hoverBackgroundColor: color2,
                borderColor: borderColor,
                data: this.aboveAverageStatusDetail.aboveAverageStatusDetail75to100			
        }, {
          label: 'Acima de 50%',
                backgroundColor: color3,
                hoverBackgroundColor: color3,
                borderColor: borderColor,
                data: this.aboveAverageStatusDetail.aboveAverageStatusDetail50to75				
        }, {
          label: 'Acima de 25%',
                backgroundColor: color4,
                hoverBackgroundColor: color4,
                borderColor: borderColor,
                data: this.aboveAverageStatusDetail.aboveAverageStatusDetail25to50				
        }];

        this.total = 0;
        this.total+=this.getListSum(this.aboveAverageStatusDetail.aboveAverageStatusDetail100);
        this.total+=this.getListSum(this.aboveAverageStatusDetail.aboveAverageStatusDetail75to100);
        this.total+=this.getListSum(this.aboveAverageStatusDetail.aboveAverageStatusDetail50to75);
        this.total+=this.getListSum(this.aboveAverageStatusDetail.aboveAverageStatusDetail25to50);

      } else {
        this.hasData=false;
      }

      if(this.total==0){
        this.hasData=false;
      }
    });
  }

  public getListSum(list:number[]):number {
    let sum: number = 0;
    if(list!=null) {
      for (let i = 0; i < list.length; i++) {
        sum+=list[i];
      }
    }
    return sum;
  }

  public getStatusOrders(e: any){
    if (e.active.length > 0) {
      this.selectedFilters = [];
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event); // isso aqui passa qts estão selecionados
      activePoints.forEach(element => { // isso aqui passa quais estão selecionados
        if(element._model!=null && element._model.datasetLabel!=null) {
          let selectedElement = element._model.datasetLabel;
          if(selectedElement=="Acima de 100%") {
            this.selectedFilters.push("100");
          } else if(selectedElement=="Acima de 75%") {
            this.selectedFilters.push("75");
          } else if(selectedElement=="Acima de 50%") {
            this.selectedFilters.push("50");
          } else if(selectedElement=="Acima de 25%") {
            this.selectedFilters.push("25");
          }
        }
      });
      if ( activePoints.length > 0) {
        const clickedElementIndex = activePoints[0]._index; // get the internal index of slice in pie chart
        const label = chart.data.labels[clickedElementIndex]; // get value by index
        const value = chart.data.datasets[0].data[clickedElementIndex];
        let status = label;
        this.router.navigate(['/main/aboveAverageOrdersByStatus', status, this.selectedFilters.toString()]);
      }
    }
  }
}
