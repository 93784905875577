import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OrderDTO } from 'src/app/models/OrderDTO';
import { I18n } from 'src/app/i18n';
import { JobTypeEnum } from 'src/app/enums/job-type';
import { OrderService } from 'src/app/services/order-service';

@Component({
  selector: 'app-order-error-calculation',
  templateUrl: './order-error-calculation.component.html',
  styleUrls: ['./order-error-calculation.component.scss']
})
export class OrderErrorCalculationComponent implements OnInit {

  orderListPaginated: OrderDTO[] = [];
  orderList: OrderDTO[] = [];
  color: string = "#FF4500";
  lengthLista = true;
  loadingOrderList: boolean = true;

  constructor(
    private t: I18n,
    private service: OrderService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.service.getCalculationErrorOrders().then(data=> {
        this.orderList = data['records'];
        this.lengthLista = this.orderList.length > 8 ? false : true;
        this.loadingOrderList = false;
      }).catch(error=>  {
    });
  }

  getTypeEnum(enumCode: string){
    return JobTypeEnum[enumCode];
  }

  ItensPaginator(ev) {
    this.orderListPaginated = ev;
    this.loadingOrderList = false;
    this.cd.detectChanges();
  }

  
  downloadOrderFile(erpJob:string) {
    this.service.generatePdfFile(erpJob).then(data=> {
    }).catch(error=>  {
      console.log("erro ao fazer download do pdf");
    });
  }

  openOrderFile(erpJob:string) {
    this.service.getPdfFile(erpJob).then(data=> {
      var url = window.URL.createObjectURL(data);

      var anchor = document.createElement('a');
      anchor.href = url;
      anchor.target = '_blank';

      anchor.click();
    }).catch(error=>  {
      console.log("erro ao abrir o pdf");
    });
  }
  
}
