import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpStatus } from '../enums/http-status';
import { StorageHandler } from './storage-handler';
import { Table } from '../enums';
import { User } from '../models/User';
import { RouteConfig } from '../enums/route-config';
import { ApiRoute } from '../enums/api-route';
import { AppConfig } from '../../environments/config';
import { I18n } from '../i18n';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class ServiceHandler {
    constructor( 
        public http: HttpClient,
        public router: Router,
        public i18n: I18n,
        private keycloakService: KeycloakService
    ){ }

    Post(path: string, body: any, isFullPath = false): Promise<any> {
        return this.GetHeaders().then((headers) => {
            return this.http.post(this.apiPath(path, isFullPath), body, headers).toPromise()
                .catch(err => this.handleError(err, Promise.reject));
        });     
    }

    Get(path: string, isFullPath = false): Promise<any> {
        return this.GetHeaders().then((headers) => {
            return this.http.get(this.apiPath(path, isFullPath), headers).toPromise()
                .catch(err => {
                    this.handleError(err, Promise.reject)
                }); 
        });
    }

    GetPDF(path: string, isFullPath = false): Promise<any> {
        return this.GetPdfHeaders().then((headers) => {
            return this.http.get(this.apiPath(path, isFullPath), headers).toPromise()
                .catch(err => {
                    this.handleError(err, Promise.reject)
                }); 
        });
    }

    Delete(path: string, isFullPath = false): Promise<any> {
        return this.GetHeaders().then((headers) => {
            this.http.delete(this.apiPath(path,isFullPath), headers).toPromise()
                .catch(err => this.handleError(err, Promise.reject));
        });
    }

    private GetHeaders() : Promise<{headers: HttpHeaders}> {
        return this.getLabId().then((labId) => {
                let h = new HttpHeaders();
                h = h.set("laboratory_document", labId);
                h = h.set("Accept-Language", this.i18n.getLanguage());
                return {headers: h};
        })
    }

    private GetPdfHeaders() : Promise<{headers: HttpHeaders}> {
        return this.getLabId().then((labId) => {
                let h = new HttpHeaders();
                h = h.set("laboratory_document", labId);
                h = h.set("Accept-Language", this.i18n.getLanguage());
                h = h.set("Accept", "application/pdf");
                return {headers: h, responseType: "blob"};
        });
    }

    async getLabId(){
        let userDetails = await this.keycloakService.loadUserProfile();
        return userDetails['attributes'].labid[0];
    }

    

    private GetAuthToken() {
        let userList = StorageHandler.List(Table.USER);
        if(userList.length > 0){
            let user : User = User.fromData(userList[0]);
            return user.token;          
        }
    }

    private handleError(err, rejectFunction) {
        console.log(err);
        if(err.status == HttpStatus.UNAUTHORIZED && 
            !window.location.pathname.contains(RouteConfig.login) && 
            window.location.pathname != "/" &&
            err.url != ApiRoute.auth.default){
            this.router.navigate([RouteConfig.login]);
        }
        if(err.status == HttpStatus.FORBIDDEN && 
            !window.location.pathname.contains(RouteConfig.login) && 
            window.location.pathname != "/" &&
            err.url != ApiRoute.auth.default){
            this.router.navigate([RouteConfig.login]);
        }
        rejectFunction(err);
    }

    private apiPath(path: string, isFullPath: boolean) {
        if(path.contains("http") || isFullPath) return path;
        return AppConfig.apiURL + path;
    }
}