import { Injectable } from '@angular/core';
import { ServiceHandler } from '../handlers/service.handler';
import { ApiRoute } from '../enums/api-route';
import { CellLaboratoryDashboardDTO } from '../models/CellLaboratoryDashboardDTO';
import { OrderReleaseDTO } from '../models/OrderReleaseDTO';
import { of } from 'rxjs';

@Injectable()
export class CellLaboratoryService {

    constructor(
        public service: ServiceHandler
    ) { }

    getDashboardByLabid(): Promise<CellLaboratoryDashboardDTO>{
        return new Promise((resolve, reject) => {
                this.service.Get(ApiRoute.cellLaboratory.dashboard).then(data => {
                    resolve(data);
                }).catch(err => {
                    reject(err);
                });
            
        });
    }

    releaseOrdersByCell(id : string, quantity:string): Promise<OrderReleaseDTO>{
        const orderReleaseDTO: OrderReleaseDTO = new OrderReleaseDTO();
        return new Promise((resolve, reject) => {
                this.service.Post(ApiRoute.cellLaboratory.releaseOrders
                    .replace('{id}', id).replace('{quantity}', quantity), null).then(data => {
                    console.log("releaseOrdersByCell" +data);
                    orderReleaseDTO.errorList = data.errorList;
                    orderReleaseDTO.releasedJobs = data.releasedJobs;
                    orderReleaseDTO.forcedReleasedJobs = data.forcedReleasedJobs;
                    orderReleaseDTO.responseError = data.responseError;
                    resolve(orderReleaseDTO);
                }).catch(err => {
                    reject(err);
                });
            
        });
    }

    releaseOrderByErpJob(erpjob: string): Promise<OrderReleaseDTO>{
        const orderReleaseDTO: OrderReleaseDTO = new OrderReleaseDTO();
        return new Promise((resolve, reject) => {
            this.service.Post(ApiRoute.cellLaboratory.releaseOrder
                .replace('{erpjob}', erpjob), null).then(data => {
                console.log(data);
                if(data!=null){
                    orderReleaseDTO.errorList = data.errorList;
                    orderReleaseDTO.releasedJobs = data.releasedJobs;
                    orderReleaseDTO.forcedReleasedJobs = data.forcedReleasedJobs;
                    orderReleaseDTO.responseError = data.responseError;
                    resolve(orderReleaseDTO);
                }
            }).catch(err => {
                reject(err);
            });
        });
    }
}