import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { I18n } from 'src/app/i18n';
import { SectorStatusDTO } from 'src/app/models/SectorStatusDTO';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';
import { StatusManagementService } from 'src/app/services/status-management-service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-daily-production-detail',
  templateUrl: './daily-production-detail.component.html',
  styleUrls: ['./daily-production-detail.component.scss']
})
export class DailyProductionDetailComponent implements OnInit {

  loading = false;

  constructor(
    private service: DataAnalyticsService,
    private statusMngtService: StatusManagementService,
    private t: I18n
  ) { }

  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'top',
      align: 'end',
      labels: {
        boxWidth: 20,
        filter: (legendItem, data) => (typeof legendItem.text !== 'undefined')
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 12,
        },
        display: function(context) {
          return context.dataset.data[context.dataIndex] !== 0;
       }
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        id: "y-axis",
        display: true,
        position: "left",
        ticks: {
          beginAtZero: true,
          stepSize: 1
        }
      }],
    },
    maintainAspectRatio: false,
  };

  public barChartType: ChartType = 'bar';
  public barChartData: ChartDataSets[] = [];
  public barChartLabels: String[] = []; //Devem ser listados como label todos os status que tenham pelo menos um pedido
  public inChartData: number[] = [];
  public outChartData: number[] = [];
  public startDate: Date;
  public endDate: Date;
  public datePicker: Date;
  public formattedStartDate: string = "";
  public formattedEndDate: string = "";
  public filters: SectorStatusDTO[] = [];
  public selectedSector: number = 0;
  public barChartPlugins = [pluginDataLabels];

  ngOnInit() {
    this.loading = true;
    this.barChartLabels = [];
    this.barChartData = [];
    this.loadData();
  };

  loadData() {
    this.filters = [];

    this.statusMngtService.getSectors().then(data => {
      data.forEach(sector => {
          this.filters.push(sector);
      });
    });
  }
  
  public getData(){

    if(this.datePicker!=undefined){

      this.datePicker.setHours(0);
      this.datePicker.setMinutes(0);
      this.datePicker.setSeconds(0);

      const datepipe: DatePipe = new DatePipe('en-US');
      this.formattedStartDate = datepipe.transform(this.datePicker, 'yyyy-MM-dd HH:mm:ss');

      this.datePicker.setHours(23);
      this.datePicker.setMinutes(59);
      this.datePicker.setSeconds(59);

      this.formattedEndDate = datepipe.transform(this.datePicker, 'yyyy-MM-dd HH:mm:ss');

      this.service.getDailyProductionByHour(this.formattedStartDate, this.formattedEndDate, this.selectedSector.toString()).then(data => {
        this.inChartData = data.inData; // verify if empty
        this.outChartData = data.outData; // verify if empty
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.barChartLabels = data.positionData;
        this.getLineChartData();
      })

    }

  }

  public getLineChartData() {
    this.barChartData = [{
      label: "IN",
      borderColor: '#ff0000',
      backgroundColor: '#ff0000',
      fill: false,
      lineTension:0,	
      spanGaps:true,
      data: this.inChartData,
      yAxisID: "y-axis"
    }, {
      label: "Out",
      borderColor: '#FFA500',
      backgroundColor: '#FFA500',
      fill: false,
      lineTension:0,	
      spanGaps:true,
      data: this.outChartData,
      yAxisID: "y-axis"
    }]

    this.loading=false;
  }

  onDateChange() {
    if(this.datePicker!=undefined){
      this.datePicker.setHours(0);
      this.datePicker.setMinutes(0);
      this.datePicker.setSeconds(0);
      const datepipe: DatePipe = new DatePipe('en-US');
      this.formattedStartDate = datepipe.transform(this.datePicker, 'yyyy-MM-dd HH:mm:ss');
      this.datePicker.setHours(23);
      this.datePicker.setMinutes(59);
      this.datePicker.setSeconds(59);
      this.formattedEndDate = datepipe.transform(this.datePicker, 'yyyy-MM-dd HH:mm:ss');
    }
    if(!this.formattedStartDate.equalsIgnoreCase("") 
        && !this.formattedEndDate.equalsIgnoreCase("")
        && this.selectedSector!=0) {
          this.getData();
    }
  }

  onFilterChange(sectorId: any) {
    this.selectedSector=sectorId;
    if(!this.formattedStartDate.equalsIgnoreCase("") 
        && !this.formattedEndDate.equalsIgnoreCase("")
        && this.selectedSector!=0) {
          this.getData();
    }
  }
}
