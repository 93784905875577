export class ServiceRateDetailedDTO {
    
    todayPlusFive: number[];
	todayPlusFour: number[];
	todayPlusThree: number[];
	todayPlusTwo: number[];
	todayPlusOne: number[];
	today: number[];
	todayMinusOne: number[];
	todayMinusTwo: number[];
	todayMinusThree: number[];
	todayMinusFour: number[];
} 
