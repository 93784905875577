import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
// import { CellRuleService } from 'src/app/services/Cell-Rule';
import { QueryBuilderService } from 'src/app/services/query-builder-service';
import { ActivatedRoute, Router } from '@angular/router';
import { I18n } from 'src/app/i18n';
import { ConfigQueryBuilderType } from 'src/app/enums/config-querybuilder-type';
import { CellSaveDTO } from 'src/app/models/CellSaveDTO';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../shared/modal/modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastsComponent } from '../shared/toasts/toasts.component';
import { RouteConfig } from 'src/app/enums/route-config';


@Component({
  selector: 'app-filter-associated',
  templateUrl: './filter-associated.component.html',
  styleUrls: ['./filter-associated.component.scss']
})
export class FilterAssociatedComponent implements OnInit {
  loading = true;
  taxfil = [];
  searchName: string;
  lengthLista = false;
  associated = 0;
  cellId: string;
  constructor(
    private cd: ChangeDetectorRef,
    private t: I18n,
    private qbService: QueryBuilderService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private toast: MatSnackBar
  ) {

  }
  filters = [];
  filtersFull = [];
  ngOnInit() {
    this.cellId = this.activatedRoute.snapshot.paramMap.get('id');
    this.GetFilter();
  }
  GetFilter() {
    this.qbService.getRulesListByCellByStatus(this.cellId, ConfigQueryBuilderType[1])
      .then(data => {
        this.filtersFull = data;
        this.filters = data;
        data.map(i => {
          if (i.associated) {
            this.taxfil.push(i.id);
            return this.associated++;
          }
        });
        this.lengthLista = this.filters.length > 8 ? true : false;
        this.loading = false;
  });
}
  ItensPaginator(ev) {
    this.filters = ev;
    this.loading = false;
    this.cd.detectChanges();
  }
  changefilter(env) {
    this.filters.map((fil) => {
      if (fil.id == env) {
        fil.associated = !fil.associated;
        if (fil.associated) {
          this.associated++;
          this.taxfil.push(env);
        } else {
          for (var i = 0; i < this.taxfil.length; i++) {
            if (this.taxfil[i] === env) {
              this.associated--;
              this.taxfil.splice(i, 1);
            }
          }
        }
      }
    });
  }
  search() {
    this.filters = this.filtersFull.filter(fill => {
      return (!this.searchName || fill.name.toLowerCase().contains(this.searchName.toLowerCase()));
    });
    this.lengthLista = this.filters.length > 8 ? true : false;
  }
  clear() {
    this.searchName = '';
    this.search()

  }
  confirmSave() {
    this.dialog.closeAll();
    const modal = this.dialog.open(ModalComponent, {
      data: {
        body: this.t.i18n.page.modalText.Confirmed.body,
        textButtonRefused: this.t.i18n.page.modalText.Deleted.textButtonRefused,
        textButtonConfirmed: this.t.i18n.page.modalText.Deleted.textButtonConfirmed,
      }
    });
    modal.componentInstance.onAdd.subscribe(() => {
      this.save()
    });
    
    
  }

  save(){    
    let save = new CellSaveDTO();
    save.ids=this.taxfil;
    this.qbService.saveCellRules(this.cellId,save )
      .then(data => {
        this.router.navigate([RouteConfig.laboratory]);
        this.openToast(this.t.i18n.page.toastsText.saveConfirmation);
      })
      .catch((err) => {
     
      });
  }

  openToast(text: string) {
    this.toast.openFromComponent(ToastsComponent, {
      data: text
    });
  }

  cancel() {
    this.dialog.closeAll();
    const modal = this.dialog.open(ModalComponent, {
      data: {
        body: this.t.i18n.page.modalText.YesNo.body,
        textButtonRefused: this.t.i18n.page.modalText.YesNo.textButtonRefused,
        textButtonConfirmed: this.t.i18n.page.modalText.YesNo.textButtonConfirmed,
      }
    });
    modal.componentInstance.onAdd.subscribe(() => {
      this.router.navigate([RouteConfig.laboratory]);
    });
    }

}
