import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { I18n } from 'src/app/i18n';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';

const blueLine = 'rgb(111,168,220)';
const orangeLine = '#FF7401';
const redLine = '#FF2E01';

@Component({
  selector: 'app-weekly-volume-card',
  templateUrl: './weekly-volume-card.component.html',
  styleUrls: ['./weekly-volume-card.component.scss']
})
export class WeeklyVolumeCardComponent implements OnInit {

  constructor(
    private router: Router,
    private service: DataAnalyticsService,
    private t: I18n
  ) { }

  public chartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 20
      }
    },
    tooltips: {
      enabled: false
    },
    layout: {
      padding: {
        //right: 50
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        id: "y-axis",
        display: true,
        position: "left",
        ticks: {
          beginAtZero: true,
          stepSize: 1
        }
      }]
    },
    maintainAspectRatio: false,
  };

  public chartType: ChartType = 'line';
  public chartLegend = true;
  public chartData: ChartDataSets[] = [];
  public chartLabels: String[] = []; //Devem ser listados como label todos os status que tenham pelo menos um pedido
  public wipChartData: number[] = [];
  public inChartData: number[] = [];
  public outChartData: number[] = [];
  public startDate: Date;
  public endDate: Date;

  ngOnInit() {
    this.chartLabels = ['Sab', 'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex'];
    //this.chartLabels = [];
    this.chartData = []
    this.getData();
  }

  public getData() {
    this.service.listVolumeDataWeekly().then(data => {
      this.inChartData = data.inData; // verify if empty
      this.wipChartData = data.wipData; // verify if empty
      this.outChartData = data.outData; // verify if empty
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.chartLabels = data.positionData;
      this.getLineChartData();
    });
  }

  public getLineChartData() {
    this.chartData = [{
      label: "In",
      borderColor: redLine,
      backgroundColor: redLine,
      fill: false,
      lineTension:0,	
      spanGaps:true,
      data: this.inChartData,
      yAxisID: "y-axis",
    },
    {
      label: "WIP",
      borderColor: blueLine,
      backgroundColor: blueLine,
      fill: false,
      lineTension:0,	
      spanGaps:true,
      data: this.wipChartData,
      yAxisID: "y-axis"
  },
  {
      label: "Out",
      borderColor: orangeLine,
      backgroundColor: orangeLine,
      fill: false,
      lineTension:0,	
      spanGaps:true,
      data: this.outChartData,
      yAxisID: "y-axis"
    }];
  }

}
