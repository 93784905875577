import { Injectable } from '@angular/core';
import { ServiceHandler } from '../handlers/service.handler';
import { OrderDTO } from '../models/OrderDTO';
import { ApiRoute } from '../enums/api-route';
import { OrderDetailByCellTabDTO } from '../models/OrderDetailByCellTabDTO';
import { OrderTrackingDTO } from '../models/OrderTrackingDTO';
import { FeasibilityImageDTO } from '../models/FeasibilityImageDTO';
import { FeasibilityDTO } from '../models/FeasibilityDTO';
import { CertificateResponseDTO } from '../models/CertificateResponseDTO';
import { LaboratoryPrinterDTO } from '../models/LaboratoryPrinterDTO';

@Injectable()
export class OrderService {
    orderList: OrderDTO[];

    constructor(
        public service: ServiceHandler
    ) { }

    listByStatus(status: string): Promise<OrderDTO[]> {
        return new Promise((resolve, reject) => {
            if (this.orderList) {
                resolve(this.orderList);
            } else {
                this.service.Get(ApiRoute.order.status.replace('{searchEnum}', status)).then(data => {
                    resolve(data);
                }).catch(err => {
                    reject(err);
                });
            }
        });
    }

    getPendingAnalysisOrders(): Promise<OrderDTO[]> {
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.order.getPendingAnalysisOrders).then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    getCalculationErrorOrders(): Promise<OrderDTO[]> {
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.order.getCalculationErrorOrders).then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    getOrderByCells(cellId: string): Promise<OrderDetailByCellTabDTO> {
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.order.getOrderByCells.replace('{id}', cellId)).then(data => {
                console.log(data);
                resolve(data);
            }).catch(err => {
                console.log(err)
                reject(err);
            });

        });
    }

    getOrdersByERPJob(erpJob: string): Promise<OrderDTO[]> {
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.order.getjobId.replace('{erpjob}', erpJob)).then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });

        });
    }

    getOrderTracking(orderId: string): Promise<OrderTrackingDTO> {
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.order.getOrderTracking.replace('{orderId}', orderId)).then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });

        });
    }
    postErrorCertificate(orderId: string): Promise<OrderTrackingDTO> {
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.order.generateCertified.replace('{orderId}', orderId)).then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });

        });
    }
    getErrorCertificate(): Promise<OrderTrackingDTO> {
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.order.errorCertified).then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });

        });
    }

    getCertificateReprint(orderId): Promise<String> {
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.order.generateCertified.replace('{orderId}', orderId)).then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            })
        })
    }

    getFeasibilty(orderId): Promise<FeasibilityDTO> {
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.order.getFeasibilty.replace('{orderId}', orderId)).then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            })
        })
    }

    postGenerateCertificate(erpjob: string, printer: string): Promise<CertificateResponseDTO> {
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.order.generateCertificateByJobId
                .replace('{erpjob}', erpjob,).replace('{printer}', printer)).then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });

        });
    }

    getPrinters(): Promise<LaboratoryPrinterDTO[]> {
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.laboratory.getPrinters).then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });

        });
    }

    generatePdfFile(erpJob): Promise<OrderDTO[]>{
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.order.generatePdfFile
                .replace('{erpJob}', erpJob)).then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    getPdfFile(erpJob): Promise<Blob>{
        return new Promise((resolve, reject) => {
            this.service.GetPDF(ApiRoute.order.getPdfFile
                .replace('{erpJob}', erpJob)).then(data => {
                    var byteArray = new Uint8Array(data.binFileImage);
                    var blob = new Blob([byteArray], { type: 'application/pdf' });
                resolve(data);
            }).catch(err => {
                console.log('->')
                reject(err);
            });
        });
    }
}