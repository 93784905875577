import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { I18n } from 'src/app/i18n';
import { BrokenStatusDTO } from 'src/app/models/BrokenStatusDTO';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';

@Component({
  selector: 'app-top-breaks-detail',
  templateUrl: './top-breaks-detail.component.html',
  styleUrls: ['./top-breaks-detail.component.scss']
})
export class TopBreaksDetailComponent implements OnInit {

  hasData = false;

  constructor(
    private router: Router,
    private service: DataAnalyticsService,
    private t: I18n
  ) { }

  public chartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      intersect: false
    },
    layout: {
      padding: {
        //right: 50
      }
    },
    scales: {
      xAxes: [{
        stacked: true,
        display: false,
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        stacked: true,
        gridLines: {
          display: false
        }
      }]
    },
    maintainAspectRatio: false,
  };

  public chartType: ChartType = 'horizontalBar';
  public chartLegend = true;
  public chartData: ChartDataSets[] = [];
  public chartLabels: string[] = []; //Devem ser listados como label todos os status que tenham pelo menos um pedido
  public brokenStatusData: BrokenStatusDTO[];
  
  ngOnInit() {
    this.chartLabels = [];
    this.hasData = false;
    this.getBarChartData();
  }

  public getBarChartData() {
    this.service.getDetailedBrokenStatus().then(data => {
      this.brokenStatusData = data;
      if (this.brokenStatusData.length>0){
        this.hasData = true;
      }
      for (let i = 0; i < this.brokenStatusData.length; i++) {
        this.chartLabels.push(this.brokenStatusData[i].externalStatusCode + " - " + this.brokenStatusData[i].externalStatusName)
      }
      this.chartData = [{
        borderColor: 'rgb(255,153,0)',
        backgroundColor: 'rgb(255,153,0)',
        data: []
      }];
      for (let i = 0; i < this.brokenStatusData.length; i++) {
        this.chartData[0].data.push(this.brokenStatusData[i].numberHistory);
      }
    });
  }

}
