import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n';
import * as $ from 'jquery';
import { TestBed } from '@angular/core/testing';
import { OrderTrackingDTO } from 'src/app/models/OrderTrackingDTO';
import { OrderService } from 'src/app/services/order-service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {
  interval: any;

  constructor(
    private t: I18n,
    private service: OrderService,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) { }
  isLoading = true;
  language = this.t.getLanguage();
  veri = true;

  request: OrderTrackingDTO;

  he = 0;
  orderId = '';
  animation = false;
  productionFinished = false;
  canceled = false;

  ngOnInit() {
    this.orderId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getData();
    this.atualizarautomtico();
  }

  atualizarautomtico() {
   this.interval =  setInterval(() => {
      this.getData();

    }, 60000);
  }
  repetido(env) {
    let validador = 0;
    this.request.history.map(hist => {
      if (hist.status === env.status) {
        validador++;
      }
    });
    return validador

  }
  private getData() {
    this.productionFinished = false;
    this.canceled = false;
    this.service.getOrderTracking(this.orderId).then(track => {
      this.request = track;
      if (track.history) {
        let quem = null;
        let i = 1;
        track.history.map(hist => {
          let teste = track.history.filter(x => x.status == hist.status)
          for (let y = 0; y < teste.length; y++) {
            if (y < teste.length - 1) {
              teste[y].type = 2
            }
          }
          if (hist.broken) {
            hist.type = 3;
          }
        });
        this.he = this.request.history.length - 1;
        this.verifyOpenOrder();
        /*this.verifyProductionFinished();
        this.verifyCanceled(); */
        console.log(this.request);
      }
      this.isLoading = false;
      setTimeout(() => {
        this.animation = false;
      }, 500);
    }).catch((e) => {
      this.isLoading = false;
      this.animation = false;
    });
  }
  atualizarnow() {
    this.getData();
  }

  verifyOpenOrder(){
    if(this.request.isOpenOrder==false){
      this.request.missingTime = "0";
    }
  }

  /*
  verifyProductionFinished(){
    if(this.request.lastConcludedDate!=null){
      this.productionFinished = true;
      this.request.missingTime = "0";
    }
  }

  verifyCanceled(){
    if(this.request.lastCanceledDate!=null){
      this.canceled = true;
      this.request.missingTime = "0";
    }
  }*/

  active(i) {
    let item = $('.item-' + i)
    if ($(item).hasClass('ativo')) {
      $(item).removeClass('ativo')
      $(item).addClass('inativo');
      $('.timeline .marcador').css('height', '')

    } else {
      $('.timeline').removeClass('ativo')
      $('.timeline').addClass('inativo')
      $('.timeline .marcador').css('height', '')
      $(item).removeClass('inativo')
      $(item).addClass('ativo');
      let tamanho = $('.item-' + i + ' .body-expansion').outerHeight(true) + 200;
      tamanho = tamanho + 'px';
      $('.item-' + i + ' .marcador').css('height', tamanho)
    }

  }
  style(env) {

    switch (env.type) {
      case 1:
        return '#0066b3';
      case 2:
        return '#ffbb01';
      case 3:
        return '#ff5050';
      default:
        return '#bcbcbc';
    }
  }

  back() {
    this.location.back();
  }
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }



}
