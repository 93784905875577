import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n';
import { CertificateResponseDTO } from 'src/app/models/CertificateResponseDTO';
import { LaboratoryPrinterDTO } from 'src/app/models/LaboratoryPrinterDTO';
import { OrderService } from 'src/app/services/order-service';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {

  erpjob: string = "";
  certificateResponse: CertificateResponseDTO = new CertificateResponseDTO();
  responseMessage: String = "";
  printers: LaboratoryPrinterDTO[];
  selectedPrinter: string;

  constructor(
    private t: I18n,
    private service: OrderService
  ) { }

  ngOnInit() {
    this.getPrinters();
  }

  getPrinters(){
    this.service.getPrinters().then(data => {
      this.printers = data;
    });
  }

  generateCertificate() {
    if (this.erpjob!=null && !this.erpjob.equalsIgnoreCase("") &&
      this.selectedPrinter!=null && !this.selectedPrinter.equalsIgnoreCase("")) {
      this.erpjob = parseInt(this.erpjob.trim()).toString();
      this.service.postGenerateCertificate(this.erpjob, this.selectedPrinter).then(data=> {
         this.certificateResponse = data;
         if(this.certificateResponse!=null && this.certificateResponse.successMessage!="") {
          this.responseMessage = this.certificateResponse.successMessage;
         } else if(this.certificateResponse!=null && this.certificateResponse.errorMessage!="") {
          this.responseMessage = this.certificateResponse.errorMessage;
         } else {
           this.responseMessage = "";
         }
         this.erpjob = "";
      }).catch(error=>  {
        this.responseMessage="Erro ao gerar certificado";
        this.erpjob = "";
      });
    } else {
      this.responseMessage="Todos os campos devem ser preenchidos";
    }
  }

  onKeydown(e){
    if(e.key === "Enter"){
       this.generateCertificate();
    }
  }

}
