import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { I18n } from 'src/app/i18n';
import { BreakReportItemDTO } from 'src/app/models/BreakReportItemDTO';
import { ReportService } from 'src/app/services/report-service';
import { saveAs } from 'file-saver';
import { ExtractionReportOptionDTO } from 'src/app/models/ExtractionReportOptionDTO';
import { IntegrationErrorReportItemDTO } from 'src/app/models/IntegrationErrorReportItemDTO';
import { CalculationErrorReportItemDTO } from 'src/app/models/CalculationErrorReportItemDTO';
import { CertificateErrorReportItemDTO } from 'src/app/models/CertificateErrorReportItemDTO';
import { InProductionReportItemDTO } from 'src/app/models/InProductionReportItemDTO';

@Component({
  selector: 'app-extractions',
  templateUrl: './extractions.component.html',
  styleUrls: ['./extractions.component.scss']
})
export class ExtractionsComponent implements OnInit {

  datePickerStart: Date;
  datePickerEnd: Date;
  responseMessage: String = "";
  report: BreakReportItemDTO[] = null;
  integrationErrorReport: IntegrationErrorReportItemDTO[] = null;
  calculationErrorReport: CalculationErrorReportItemDTO[] = null;
  certificateErrorReport: CertificateErrorReportItemDTO[] = null;
  inProductionReport: InProductionReportItemDTO[] = null;
  reportStr: string = "";
  reportOptions: ExtractionReportOptionDTO[] = [];
  selectedReportOption: number;
  reportName = "report.csv";

  constructor(
    private t: I18n,
    private service: ReportService,
  ) { }

  ngOnInit() {
    this.getReportOptions();
  }

  getReportOptions(){
    let integrationErrorOption: ExtractionReportOptionDTO = new ExtractionReportOptionDTO();
    integrationErrorOption.id=1;
    integrationErrorOption.reportName="Erros de Integração";

    let calculationErrorOption: ExtractionReportOptionDTO = new ExtractionReportOptionDTO();
    calculationErrorOption.id=2;
    calculationErrorOption.reportName="Erros de Cálculo";

    let certificateErrorOption: ExtractionReportOptionDTO = new ExtractionReportOptionDTO();
    certificateErrorOption.id=3;
    certificateErrorOption.reportName="Erros de Certificado";

    let inProductionOption: ExtractionReportOptionDTO = new ExtractionReportOptionDTO();
    inProductionOption.id=4;
    inProductionOption.reportName="In Production";

    this.reportOptions.push(integrationErrorOption);
    this.reportOptions.push(calculationErrorOption);
    this.reportOptions.push(certificateErrorOption);
    this.reportOptions.push(inProductionOption);
  }

  search() {

    if(this.datePickerStart==null || this.datePickerEnd==null){
      this.responseMessage="É preciso informar as duas datas para gerar o relatório";
      return;
    }

    if(this.datePickerStart > this.datePickerEnd){
      this.responseMessage="A data de início deve ser menor ou igual do que a data de fim";
      return;
    }

    let differenceInTime = this.datePickerEnd.getTime() - this.datePickerStart.getTime();
    let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    if(differenceInDays>30){
      this.responseMessage="O relatório deve possuir, no máximo, 30 dias entre a data de início e a data de fim";
      return;
    }

    let startDate = moment(this.datePickerStart).format("YYYY-MM-DD");
    let endDate = moment(this.datePickerEnd).format("YYYY-MM-DD");

    if(this.selectedReportOption==1){
      this.service.getIntegrationErrorReport(startDate, endDate).then(data=> {
        this.integrationErrorReport = data;
        this.reportName="relatorio-integration-error.csv";
        this.responseMessage="";
        this.formatIntegrationErrorReportAsText();
        var blob = new Blob([this.reportStr], {type: "text/html"});
        saveAs(blob, this.reportName);
      }).catch(error=>  {
        console.log(error);
        this.responseMessage="Erro ao gerar o relatório";
      });
    }

    else if(this.selectedReportOption==2){
      this.service.getCalculationErrorReport(startDate, endDate).then(data=> {
        this.calculationErrorReport = data;
        this.reportName="relatorio-calculation-error.csv";
        this.responseMessage="";
        this.formatCalculationErrorReportAsText();
        var blob = new Blob([this.reportStr], {type: "text/html"});
        saveAs(blob, this.reportName);
      }).catch(error=>  {
        console.log(error);
        this.responseMessage="Erro ao gerar o relatório";
      });
    }

    else if(this.selectedReportOption==3){
      this.service.getCertificateErrorReport(startDate, endDate).then(data=> {
        this.certificateErrorReport = data;
        this.reportName="relatorio-certificate-error.csv";
        this.responseMessage="";
        this.formatCertificateErrorReportAsText();
        var blob = new Blob([this.reportStr], {type: "text/html"});
        saveAs(blob, this.reportName);
      }).catch(error=>  {
        console.log(error);
        this.responseMessage="Erro ao gerar o relatório";
      });
    }

    else if(this.selectedReportOption==4){
      this.service.getInProductionReport(startDate, endDate).then(data=> {
        this.inProductionReport = data;
        this.reportName="relatorio-in-production.csv";
        this.responseMessage="";
        this.formatInProductionReportAsText();
        var blob = new Blob([this.reportStr], {type: "text/html"});
        saveAs(blob, this.reportName);
      }).catch(error=>  {
        console.log(error);
        this.responseMessage="Erro ao gerar o relatório";
      });
    }

    else {
      this.responseMessage="O relatório deve ser informado";
      return;
    }

  }

  formatIntegrationErrorReportAsText(){
    this.reportStr = "\"st_erp_job\";\"st_job_type\";\"st_name\";\"dt_create\";\"data_hora\";\"dt_delete\"";
    this.reportStr+="\n";
    this.integrationErrorReport.forEach(reportItem => {
      if(reportItem.job==null){
        reportItem.job="";
      }
      if(reportItem.jobType==null){
        reportItem.jobType="";
      }
      if(reportItem.name==null){
        reportItem.name="";
      }
      if(reportItem.createdAt==null){
        reportItem.createdAt="";
      }
      if(reportItem.dateHour==null){
        reportItem.dateHour=null;
      }
      if(reportItem.deletedAt==null){
        reportItem.deletedAt="";
      }
      this.reportStr+="\""+reportItem.job+"\";"
                          +reportItem.jobType+";"
                          +reportItem.name+";"
                          +reportItem.createdAt+";"
                          +reportItem.dateHour+";"
                          +reportItem.deletedAt+"\n";
    });

  }

  formatCalculationErrorReportAsText(){
    this.reportStr = "\"st_erp_job\";\"st_job_type\";\"st_name\";\"dt_create\";\"st_status\";\"dt_inic_status\";\"st_reason\"";
    this.reportStr+="\n";
    this.calculationErrorReport.forEach(reportItem => {
      if(reportItem.job==null){
        reportItem.job="";
      }
      if(reportItem.jobType==null){
        reportItem.jobType="";
      }
      if(reportItem.name==null){
        reportItem.name="";
      }
      if(reportItem.createdAt==null){
        reportItem.createdAt="";
      }
      if(reportItem.status==null){
        reportItem.status="";
      }
      if(reportItem.initStatusAt==null){
        reportItem.initStatusAt="";
      }
      if(reportItem.reason==null){
        reportItem.reason="";
      }
      this.reportStr+="\""+reportItem.job+"\";"
                          +reportItem.jobType+";"
                          +reportItem.name+";"
                          +reportItem.createdAt+";"
                          +reportItem.status+";"
                          +reportItem.initStatusAt+";"
                          +reportItem.reason+"\n";
    });

  }

  formatCertificateErrorReportAsText(){
    this.reportStr = "\"st_erp_job\";\"dt_create\";\"st_status\";\"dt_init_status\";\"st_reason\"";
    this.reportStr+="\n";
    this.certificateErrorReport.forEach(reportItem => {
      if(reportItem.job==null){
        reportItem.job="";
      }
      if(reportItem.createdAt==null){
        reportItem.createdAt="";
      }
      if(reportItem.status==null){
        reportItem.status="";
      }
      if(reportItem.initStatusAt==null){
        reportItem.initStatusAt="";
      }
      if(reportItem.reason==null){
        reportItem.reason="";
      }
      this.reportStr+="\""+reportItem.job+"\";"
                          +reportItem.createdAt+";"
                          +reportItem.status+";"
                          +reportItem.initStatusAt+";"
                          +reportItem.reason+"\n";
    });
  }

  formatInProductionReportAsText(){
    this.reportStr = "\"st_erp_job\";\"st_job_type\";\"st_name\";\"dt_create\";\"st_status\";\"dt_inic_status\"";
    this.reportStr+="\n";
    this.inProductionReport.forEach(reportItem => {
      if(reportItem.job==null){
        reportItem.job="";
      }
      if(reportItem.jobType==null){
        reportItem.jobType="";
      }
      if(reportItem.name==null){
        reportItem.name="";
      }
      if(reportItem.createdAt==null){
        reportItem.createdAt="";
      }
      if(reportItem.status==null){
        reportItem.status="";
      }
      if(reportItem.initStatusAt==null){
        reportItem.initStatusAt="";
      }

      this.reportStr+="\""+reportItem.job+"\";"
                          +reportItem.jobType+";"
                          +reportItem.name+";"
                          +reportItem.createdAt+";"
                          +reportItem.status+";"
                          +reportItem.initStatusAt+"\n";
    });

  }

}