
let basePath = '/api';

export const ApiRoute = {
    auth: {
        default: basePath + '/auth', // GET
        login: basePath + '/auth/login', // POST
    },
    user: {
        default: basePath + '/user'
    },
    order: {
        default: basePath + '/order',
        status: basePath + '/order/search/{searchEnum}',
        getOrderByCells: basePath +'/order/search/cell/{id}/tabs',
        getOrderTracking:  basePath +'/order/tracking/{orderId}',
        errorCertified: basePath + '/order/search/CERTIFIED_ERROR',
        getjobId: basePath + '/order/searchErpJob/{erpjob}',
        generateCertified: basePath + '/order/generateCertified/{orderId}',
        generateCertificateByJobId: basePath + '/order/generateCertificateByJobId/{erpjob}/{printer}',
        getFeasibilty: basePath + '/order/feasibility/{orderId}',
        getCalculationErrorOrders: basePath + '/order/search/calculationError',
        getPendingAnalysisOrders: basePath + '/order/search/pendingAnalysis',
        generatePdfFile: basePath + '/order/generatePdfFile/{erpJob}',
        getPdfFile: basePath + '/order/getPdfFile/{erpJob}'
    },
    laboratory: {
        getPrinters: basePath + '/laboratory/getPrinters',
    },
    queryBuilder: {
        config: basePath + '/querybuilder/config/fields',
        save: basePath +'/querybuilder/save',
        listRules : basePath +'/querybuilder/rules',
        getRule : basePath +'/querybuilder/rule/{id}',
        delete : basePath +'/querybuilder/delete/{id}',
        getRulesByCell : basePath +'/querybuilder/rules/cell/{id}/{status}',
        saveCellRules:  basePath +'/querybuilder/rules/cell/{id}/save',   
    },
    cellLaboratory:{
        dashboard: basePath +'/cell/dashboard',
        getRule: basePath + '/querybuilder/rules/Cell/{id}',
        getRuleType: basePath + '/querybuilder/rules/Cell/{id}/{type}',
        releaseOrders: basePath + '/cell/{id}/release-orders/{quantity}',
        releaseOrder: basePath + '/cell/release-order/{erpjob}'
    }, 
    statusManagement: {
        listStatus: basePath +'/statusManagement/laboratory',
        saveStatus: basePath +'/statusManagement/laboratory/save',
        listSectors: basePath + '/statusManagement/sectors'
    },
    dataAnalytics: {
        listLateOrders: basePath + '/dataAnalytics/lateOrders/{orderLenseType}',
        listLateOrdersSectors: basePath + '/dataAnalytics/lateOrdersSectors/{orderLenseType}',
        listLateOrdersStatusWithNoSector: basePath + '/dataAnalytics/lateOrdersStatusWithNoSector/{orderLenseType}',
        listLateOrdersStatusWithSector: basePath + '/dataAnalytics/lateOrdersStatusWithSector/{orderLenseType}/{sector}',
        listLateOrdersBySectorAndStatus: basePath + '/dataAnalytics/lateOrdersBySectorAndStatus/{orderLenseType}/{sector}/{mngtStatus}/{days}',
        listLateOrdersByNoSectorAndStatus: basePath + '/dataAnalytics/lateOrdersByNoSectorAndStatus/{orderLenseType}/{mngtStatus}/{days}',
        listServiceRateStatus: basePath +'/dataAnalytics/serviceRateWithStatus',
        listLenseQuantityByType: basePath + '/dataAnalytics/lenseQuantityByType/{orderLenseType}',
        listVolumeData: basePath + '/dataAnalytics/volumeData/{startDate}/{endDate}',
        listVolumeDataWeekly: basePath + '/dataAnalytics/volumeDataWeekly',
        listServiceRate: basePath + '/dataAnalytics/serviceRate/{serviceRateLenseType}',
        listDetailedServiceRate: basePath + '/dataAnalytics/detailedServiceRate/{serviceRateLenseType}',
        listServiceRateWeekly: basePath + '/dataAnalytics/serviceRateWeekly',
        listDetailedServiceRateWeekly: basePath + '/dataAnalytics/detailedServiceRateWeekly/{startDate}/{endDate}/{client}',
        listBrokenStatus: basePath + '/dataAnalytics/brokenStatus',
        listDetailedBrokenStatus: basePath + '/dataAnalytics/detailedBrokenStatus',
        listReworkStatus: basePath + '/dataAnalytics/reworkStatus',
        listDetailedReworkStatus: basePath + '/dataAnalytics/detailedReworkStatus',
        listAboveAverageStatus: basePath + '/dataAnalytics/aboveAverageStatus',
        listDetailedAboveAverageStatus: basePath + '/dataAnalytics/detailedAboveAverageStatus',
        listAboveAverageStatusOrders: basePath + '/dataAnalytics/detailedAboveAverageStatusOrders/{orderStatus}/{selectedFilters}',
        listDailyProduction: basePath + '/dataAnalytics/dailyProduction/{orderLenseType}',
        listDailyProductionByHour: basePath + '/dataAnalytics/dailyProductionByHour/{startDate}/{endDate}/{sector}',
    },
    report: {
        break: basePath + '/report/breaks/{startDate}/{endDate}',
        integrationError: basePath + '/report/integration-error/{startDate}/{endDate}',
        calculationError: basePath + '/report/calculation-error/{startDate}/{endDate}',
        certificateError: basePath + '/report/certificate-error/{startDate}/{endDate}',
        inProduction: basePath + '/report/in-production/{startDate}/{endDate}',
    }
  
}