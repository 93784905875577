export class User {
    id?: number;
    token: string;
    name: string;
    username: string;

    constructor(){}

    
    static fromData(data): User {
        let user = Object.assign(new this(), data);      
        return user;
    }
    
}