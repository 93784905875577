import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { OrderDTO } from 'src/app/models/OrderDTO';
import { I18n } from 'src/app/i18n';
import { OrderService } from 'src/app/services/order-service';
import { JobTypeEnum } from 'src/app/enums/job-type';


@Component({
  selector: 'app-order-error-integration',
  templateUrl: './order-error-integration.component.html',
  styleUrls: ['./order-error-integration.component.scss']
})
export class OrderErrorIntegrationComponent implements OnInit {

  expandedElement: OrderDTO | null;

  orderListPaginated: OrderDTO[] = [];

  orderList: OrderDTO[] = [];
  lengthLista = true;

  color: string = "#8C32F3";


  loadingOrderList: boolean = true;

  constructor(
    private t: I18n,
    private service: OrderService,
    private cd: ChangeDetectorRef
  ) { }


  ngOnInit() {

    this.service.listByStatus('INTEGRATION_ERROR').then(data=> {
      this.orderList = data['records'];
      this.lengthLista = this.orderList.length > 8 ? false : true;
      this.loadingOrderList = false;
    }).catch(error=>  {
    });
   
  }

  async delay(ms: number) {
      await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => {});
  }

  getTypeEnum(enumCode: string){
    return JobTypeEnum[enumCode];
  }

  ItensPaginator(ev) {
    this.orderListPaginated = ev;
    this.loadingOrderList = false;
    this.cd.detectChanges();
  }

}
