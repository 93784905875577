import * as $ from 'jquery';

export class DOMManipulation {



    static changeByClass(classes:string, changeText: string ){
        var items: any[];
        items = $(classes)
        for (var i = 0; i < items.length; i++) {
            items[i].textContent = changeText;
        }
    }
  

    static changeAll(att: string, findText: string, changeText: string) {
        var items: any[];

        items = $(`${att}:contains(${findText})`).filter(function() {
            return $(this).text().trim() === `${findText}`;
          });

        for (var i = 0; i < items.length; i++) {
            if (att ==='button'){
                items[i].childNodes[1].nodeValue = "  " + changeText;
            }else
                items[i].textContent = changeText;

        }

    }
}
