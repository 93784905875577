import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OrderDTO } from 'src/app/models/OrderDTO';
import { I18n } from 'src/app/i18n';
import { OrderService } from 'src/app/services/order-service';
import { Router } from '@angular/router';
import { JobTypeEnum } from 'src/app/enums/job-type';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../shared/modal/modal.component';

@Component({
  selector: 'app-error-certificate',
  templateUrl: './error-certificate.component.html',
  styleUrls: ['./error-certificate.component.scss']
})
export class ErrorCertificateComponent implements OnInit {

  expandedElement: OrderDTO | null;
  orderListPaginated: OrderDTO[] = [];
  orderList: OrderDTO[] = [];
  lengthLista = true;
  color: string = "#F3C032";
  loadingOrderList: boolean = true;
  qtd: number;

  constructor(
    private t: I18n,
    private service: OrderService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.service.getErrorCertificate().then(data=> {
      console.log(data); 
       this.orderList = data['records'];
       this.lengthLista = this.orderList.length > 8 ? false : true;
       this.loadingOrderList = false;
    }).catch(error=>  {
    });
  }

  async delay(ms: number) {
      await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => {});
  }

  getTypeEnum(enumCode: string){
    return JobTypeEnum[enumCode];
  }

  ItensPaginator(ev) {
    this.orderListPaginated = ev;
    this.loadingOrderList = false;
    this.cd.detectChanges();
  }

  openModal(id: string) {
    this.dialog.closeAll();
    const modal = this.dialog.open(ModalComponent, {
      data: {
        body: this.t.i18n.page.modalText.Certificado.title,
        textButtonRefused: this.t.i18n.page.modalText.Deleted.textButtonRefused,
        textButtonConfirmed: this.t.i18n.page.modalText.Deleted.textButtonConfirmed,
      }
     
    });
    modal.componentInstance.onAdd.subscribe(() => {
      this.service.getCertificateReprint(id);
    });
  }
   
}
