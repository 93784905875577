import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar, MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent implements OnInit {

  constructor(private Toast:MatSnackBar,@Inject (MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit() {
  }  

} 
