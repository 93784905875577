
interface Array<T> {
    contains(item): boolean;
    removeFirstByProp(prop, value): void;
    orderBy(prop, asc): T[];
    orderBy(prop): T[];
    order(): T[];
    order(asc): T[];
    any(f: (obj: T) => boolean) : boolean;
    any() : boolean;
    remove() :  T[];
}
Array.prototype.contains = function(item: any){
    return this.indexOf(item) > -1;
}
Array.prototype.removeFirstByProp = function(prop: string, value: any){
    let index;
    for(let i = 0; i < this.length; i++){
        if(this[i][prop] == value){
            index = i;
            break;
        }
    }
    if(index !== undefined){
        this.splice(index, 1);
    }
}


Array.prototype.orderBy = function(p: string, asc: boolean = true) {
    let arr: any[] = this;
    if(arr.length > 0 && typeof(arr[0][p]) == 'string'){
        if(asc){
            arr = arr.sort((a, b) =>  a[p].localeCompare(b[p]));
        }else{
            arr = arr.sort((a, b) =>  b[p].localeCompare(a[p]));
        }
    }else{
        if(asc){
            arr = arr.sort((a, b) =>  a[p] > b[p] ? 1 : -1);
        }else{
            arr = arr.sort((a, b) =>  a[p] > b[p] ? -1 : 1);
        }
    }
    
    return arr;
}


Array.prototype.any = function(f: Function = null) : boolean {
    if(f)
        return this.filter(f).length > 0;
    return this.length > 0;
}


Array.prototype.remove = function() {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};
