import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { I18n } from 'src/app/i18n';
import { JobTypeEnum } from 'src/app/enums/job-type';
import { OrderService } from 'src/app/services/order-service';
import { ActivatedRoute, Router } from '@angular/router';
import { JSONUtil } from 'src/app/utils/json';
import { Location } from '@angular/common';
import { OrderDetailByCellTabDTO } from 'src/app/models/OrderDetailByCellTabDTO';


@Component({
  selector: 'app-visualizar-pedidos',
  templateUrl: './visualizar-pedidos.component.html',
  styleUrls: ['./visualizar-pedidos.component.scss']
})
export class VisualizarPedidosComponent implements OnInit {
  @Output() tabIndex = new EventEmitter();
  teste;
  cellId: string;
  lengthLista: boolean;
  constructor(
    private t: I18n,
    private orderService: OrderService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private cd: ChangeDetectorRef,
  ) { }
  loadingOrderList: boolean = true;

  searchPedido: string;
  cellDetail;
  cellDetailPaginated;
  selected = 0;
  cellDetailFull: OrderDetailByCellTabDTO;


  ngOnInit() {
    this.cellId = this.activatedRoute.snapshot.paramMap.get('id');
    this.orderService.getOrderByCells(this.cellId).then(data => {
      this.cellDetailFull = JSONUtil.copyByValue(data);
      this.cellDetail = JSONUtil.copyByValue(data);
      this.cellDetailPaginated = JSONUtil.copyByValue(this.cellDetailFull);
      this.loadingOrderList = false;
      this.lengthLista = this.cellDetail.data[this.selected].data.records.length > 8 ? false : true;
    });
  }
  getTypeEnum(enumCode: string) {
    return JobTypeEnum[enumCode];
  }
  search() {
    if (this.searchPedido) {
      this.loadingOrderList = true;
      this.cellDetail.data[this.selected].data.records =this.cellDetailFull.data[this.selected].data.records.filter(num => {
          return (!this.searchPedido || num.jobId.contains(this.searchPedido));
        }
      ); 
      this.cellDetailPaginated.data[this.selected].data.records = this.cellDetail.data[this.selected].data.records
      this.loadingOrderList = false;
    } else {
      this.cellDetail = JSONUtil.copyByValue(this.cellDetailFull);
    }
  }

  back(){
    this.location.back();
  }
  onLinkClick(event) {
    this.selected = event;
    this.searchPedido = '';
    this.cellDetailPaginated =JSONUtil.copyByValue(this.cellDetailFull);
    this.lengthLista= this.cellDetailPaginated.data[this.selected].data.records.length > 8 ? false : true;
  }

  ItensPaginator(ev) {
    this.cellDetailPaginated.data[this.selected].data.records = ev;
    this.loadingOrderList = false;
    this.cd.detectChanges();
  }
    detalhe(env){
    this.router.navigate([`/main/request-detail/${env}`]);
  }
}
