import { EditConfigurationComponent } from './pages/private/edit-configuration/edit-configuration.component';
import { ConfigurationComponent } from './pages/private/configuration/configuration.component';
import { MainComponent } from './pages/private/main/main.component';
import { HomeComponent } from './pages/private/home/home.component';
import { DashboardComponent } from './pages/private/home/components/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrderDashboardComponent } from './pages/private/order-dashboard/order-dashboard.component';
import { OrderErrorIntegrationComponent } from './components/order-error-integration/order-error-integration.component';
import { OrderErrorCalculationComponent } from './components/order-error-calculation/order-error-calculation.component';
import { ManualCalculationComponent } from './components/manual-calculation/manual-calculation.component';
import { LaboratoryComponent } from './pages/private/laboratory/laboratory.component';
import { FilterAssociatedComponent } from './components/filter-associated/filter-associated.component';
import { VisualizarPedidosComponent } from './components/visualizar-pedidos/visualizar-pedidos.component';
import { ManagerialConfigurationComponent } from './pages/private/managerial-configuration/managerial-configuration.component';
import { RequestComponent } from './pages/private/request/request.component';
import { ErrorCertificateComponent } from './components/error-certificate/error-certificate.component';
import { OrdersSearchComponent } from './pages/private/orders-search/orders-search.component';
import { LateOrdersOutDetailComponent } from './pages/private/late-orders-out-detail/late-orders-out-detail.component';
import { VolumeWipDetailComponent } from './pages/private/volume-wip-detail/volume-wip-detail.component';
import { AboveAverageOrdersDetailComponent } from './pages/private/above-average-orders-detail/above-average-orders-detail.component';
import { ServiceRateOutDetailComponent } from './pages/private/service-rate-out-detail/service-rate-out-detail.component';
import { WeeklyServiceRateDetailComponent } from './pages/private/weekly-service-rate-detail/weekly-service-rate-detail.component';
import { WeeklyVolumeDetailComponent } from './pages/private/weekly-volume-detail/weekly-volume-detail.component';
import { TopBreaksDetailComponent } from './pages/private/top-breaks-detail/top-breaks-detail.component';
import { ReworkDetailComponent } from './pages/private/rework-detail/rework-detail.component';
import { CertificateComponent } from './pages/private/certificate/certificate.component';
import { AboveAverageOrdersByStatusComponent } from './pages/private/above-average-orders-by-status/above-average-orders-by-status.component';
import { ReleaseOrderComponent } from './pages/private/release-order/release-order.component';
import { ReportBreaksComponent } from './pages/private/reports/report-breaks/report-breaks.component';
import { ExtractionsComponent } from './pages/private/reports/extractions/extractions.component';
import { DailyProductionDetailComponent } from './pages/private/daily-production-detail/daily-production-detail.component';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  {
    path: 'main', component: MainComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'configuration', component: ConfigurationComponent },
      { path: 'laboratory', component: LaboratoryComponent },
      { path: 'celula' + "/:id", component: FilterAssociatedComponent },
      { path: 'visualizar-pedidos' + "/:id", component: VisualizarPedidosComponent },
      { path: 'configuration' + "/:id", component: EditConfigurationComponent },
      { path: 'edit-configuration', component: EditConfigurationComponent },
      { path: 'managerial-configuration', component: ManagerialConfigurationComponent },
      { path: 'request-detail/:id', component: RequestComponent },
      { path: 'order-search/:search', component: OrdersSearchComponent },
      {
        path: 'orderDashboard', component: OrderDashboardComponent,
        children: [
          { path: '', redirectTo: 'integration', pathMatch: 'full' },
          { path: 'integration', component: OrderErrorIntegrationComponent },
          { path: 'calculationError', component: OrderErrorCalculationComponent },
          { path: 'manualCalculation', component: ManualCalculationComponent },
          { path: 'errorCertificate', component: ErrorCertificateComponent }
        ]
      },
      { path: 'lateOrdersOutDetail', component: LateOrdersOutDetailComponent},
      { path: 'volumeWipDetail', component: VolumeWipDetailComponent},
      { path: 'aboveAverageOrdersDetail', component: AboveAverageOrdersDetailComponent},
      { path: 'serviceRateOutDetail', component: ServiceRateOutDetailComponent},
      { path: 'weeklyServiceRateDetail', component: WeeklyServiceRateDetailComponent},
      { path: 'weeklyVolumeDetail', component: WeeklyVolumeDetailComponent},
      { path: 'topBreaksDetail', component: TopBreaksDetailComponent},
      { path: 'reworkDetail', component: ReworkDetailComponent},
      { path: 'certificate', component: CertificateComponent},
      { path: 'aboveAverageOrdersByStatus/:status/:selectedFilters', component: AboveAverageOrdersByStatusComponent},
      { path: 'releaseorder', component: ReleaseOrderComponent},
      { path: 'reportbreaks', component: ReportBreaksComponent},
      { path: 'extractions', component: ExtractionsComponent},
      { path: 'dailyProductionDetail', component: DailyProductionDetailComponent}
    ]
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
