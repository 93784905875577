import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { I18n } from 'src/app/i18n';
import { CellLaboratoryService } from 'src/app/services/cellLaboratory-service';
import { ToastsComponent } from '../toasts/toasts.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-modal-producao',
  templateUrl: './modal-producao.component.html',
  styleUrls: ['./modal-producao.component.scss']
})

export class ModalProducaoComponent implements OnInit {
  feedbackModal = false;
  errorJobs = [];
  releasedJobs = [];
  forcedReleasedJobs = [];
  loading = false;
  constructor(
    private t: I18n,
    private dialogRef: MatDialogRef<ModalProducaoComponent>,
    private service: CellLaboratoryService,
    private toast:  MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.data.number = null;
    this.data.err = null;
    this.feedbackModal=false;
  }

  onButtonClick(): void {
    this.loading = true;
    this.service.releaseOrdersByCell(this.data.id, this.data.number).then(data => { 
      console.log("quantidade de pedidos com erro ao liberar = " + data.errorList.length);
      if(data.errorList.length>0){
        this.errorJobs = data.errorList;
        console.log("os pedidos com erro ao liberar são:");
        for(let i=0; i<data.errorList.length; i++) {
          console.log(data.errorList[i]);
        }
      }
      console.log("quantidade de pedidos com liberação forçada = " + data.forcedReleasedJobs.length);
      if(data.forcedReleasedJobs.length>0){
        this.forcedReleasedJobs = data.forcedReleasedJobs;
        console.log("os pedidos com liberação forçada são:");
        for(let i=0; i<data.forcedReleasedJobs.length; i++) {
          console.log(data.forcedReleasedJobs[i]);
        }
      }
      console.log("quantidade de pedidos com sucesso ao liberar = " + data.releasedJobs.length);
      if(data.releasedJobs.length>0){
        this.releasedJobs = data.releasedJobs;
        console.log("os pedidos com sucesso ao liberar são:");
        for(let i=0; i<data.releasedJobs.length; i++) {
          console.log(data.releasedJobs[i]);
        }
      }
      console.log("mensagem de erro como resposta da requisição = " + data.responseError);
      
      this.data.number = data.releasedJobs.length;
      this.feedbackModal=true;
      this.loading = false;
      //this.dialogRef.close(this.data); 
    }).catch(err =>{
      this.data.err = err;
      this.feedbackModal = false;
      this.dialogRef.close(this.data);
      this.loading = false;
      console.log(err);
    });
  }

  feedbackModalClose() {
    this.dialogRef.close(this.data);
    this.feedbackModal=false;
  }

  cancel() {
    this.dialogRef.close();
  }

}
