import { Injectable } from '@angular/core';
import { ServiceHandler } from '../handlers/service.handler';
import { ManagementStatusDTO } from '../models/ManagementStatusDTO';
import { ApiRoute } from '../enums/api-route';
import { SectorStatusDTO } from '../models/SectorStatusDTO';

@Injectable()
export class StatusManagementService {

    constructor(
        public service: ServiceHandler
    ) { }

    getAll(): Promise<ManagementStatusDTO[]>{
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.statusManagement.listStatus).then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    saveAll(list :ManagementStatusDTO[] ): Promise<boolean>{
        return new Promise((resolve, reject) => {
            this.service.Post(ApiRoute.statusManagement.saveStatus, list).then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    getSectors(): Promise<SectorStatusDTO[]>{
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.statusManagement.listSectors).then(data => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
        });
    }

}