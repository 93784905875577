import { Component, Inject, Output, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit{
confirme = new EventEmitter();
  constructor(private  dialogRef : MatDialogRef<ModalComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }
  onAdd = new EventEmitter();
  ngOnInit() {
    
  }
onButtonClick() {
  this.onAdd.emit();
  this.dialogRef.close();
}

}
