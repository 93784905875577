import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { I18n } from 'src/app/i18n';
import { BreakReportItemDTO } from 'src/app/models/BreakReportItemDTO';
import { ReportService } from 'src/app/services/report-service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-report-breaks',
  templateUrl: './report-breaks.component.html',
  styleUrls: ['./report-breaks.component.scss']
})
export class ReportBreaksComponent implements OnInit {

  datePickerStart: Date;
  datePickerEnd: Date;
  responseMessage: String = "";
  breakReport: BreakReportItemDTO[] = null;
  breakReportStr: string = "";
  constructor(
    private t: I18n,
    private service: ReportService,
  ) { }

  ngOnInit() {
  }

  search() {

    if(this.datePickerStart==null || this.datePickerEnd==null){
      this.responseMessage="É preciso informar as duas datas para gerar o relatório";
      return;
    }

    if(this.datePickerStart > this.datePickerEnd){
      this.responseMessage="A data de início deve ser menor ou igual do que a data de fim";
      return;
    }

    let differenceInTime = this.datePickerEnd.getTime() - this.datePickerStart.getTime();
    let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    if(differenceInDays>30){
      this.responseMessage="O relatório deve possuir, no máximo, 30 dias entre a data de início e a data de fim";
      return;
    }

    let startDate = moment(this.datePickerStart).format("YYYY-MM-DD");
    let endDate = moment(this.datePickerEnd).format("YYYY-MM-DD");

    this.service.getBreakReport(startDate, endDate).then(data=> {
      this.breakReport = data;
      this.formatReportAsText();
      var blob = new Blob([this.breakReportStr], {type: "text/html"});
      saveAs(blob, "relatorio-quebras.csv");
      this.responseMessage="";
    }).catch(error=>  {
      console.log(error);
      this.responseMessage="Erro ao gerar o relatório";
    });
  }

  formatReportAsText(){
    this.breakReportStr = "\"job\";\"status code\";\"status description\";\"st_name\";\"st_product_material\";\"date\";\"lente_esq\";\"lente_dir\"";
    this.breakReportStr+="\n";
    this.breakReport.forEach(reportItem => {
      if(reportItem.statusDescription==null){
        reportItem.statusDescription="";
      }
      if(reportItem.name==null){
        reportItem.name="";
      }
      if(reportItem.productMaterial==null){
        reportItem.productMaterial="";
      }
      if(reportItem.date==null){
        reportItem.date="";
      }
      if(reportItem.leftLen==null){
        reportItem.leftLen="";
      }
      if(reportItem.rightLen==null){
        reportItem.rightLen="";
      }
      this.breakReportStr+="\""+reportItem.job+"\";"
                          +reportItem.statusCode+";"
                          +reportItem.statusDescription+";"
                          +reportItem.name+";"
                          +reportItem.productMaterial+";"
                          +"\""+reportItem.date+"\";"
                          +reportItem.leftLen+";"
                          +reportItem.rightLen+"\n";
    });

  }
}
