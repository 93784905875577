import { Component, OnInit } from '@angular/core';
import { CellLaboratoryDashboardDTO } from 'src/app/models/CellLaboratoryDashboardDTO';
import { CellLaboratoryService } from 'src/app/services/cellLaboratory-service';

@Component({
  selector: 'app-production-card',
  templateUrl: './production-card.component.html',
  styleUrls: ['./production-card.component.scss']
})
export class ProductionCardComponent implements OnInit {

  constructor(
    private service: CellLaboratoryService
  ) { }

  lab: CellLaboratoryDashboardDTO = new CellLaboratoryDashboardDTO();

  ngOnInit() {
    this.getData();
  }

  private getData() {
    this.service.getDashboardByLabid().then(data => {
      this.lab = data;
    });
  }

}
