import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OrderDTO } from 'src/app/models/OrderDTO';
import { I18n } from 'src/app/i18n';
import { OrderService } from 'src/app/services/order-service';
import { JobTypeEnum } from 'src/app/enums/job-type';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manual-calculation',
  templateUrl: './manual-calculation.component.html',
  styleUrls: ['./manual-calculation.component.scss']
})
export class ManualCalculationComponent implements OnInit {

  expandedElement: OrderDTO | null;

  orderListPaginated: OrderDTO[] = [];

  orderList: OrderDTO[] = [];
  lengthLista = true;

  color: string = "#F3C032";


  loadingOrderList: boolean = true;

  constructor(
    private t: I18n,
    private service: OrderService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) { }


  ngOnInit() {

    this.service.getPendingAnalysisOrders().then(data=> {
      this.orderList = data['records'];
      this.lengthLista = this.orderList.length > 8 ? false : true;
      this.loadingOrderList = false;
    }).catch(error=>  {
    });
   
  }

  async delay(ms: number) {
      await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => {});
  }

  getTypeEnum(enumCode: string){
    return JobTypeEnum[enumCode];
  }

  ItensPaginator(ev) {
    this.orderListPaginated = ev;
    this.loadingOrderList = false;
    this.cd.detectChanges();
  }


}
