import { Component, OnInit, Input, SimpleChanges,  } from '@angular/core';

@Component({
  selector: 'app-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrls: ['./spinner-loader.component.scss'],

})
export class SpinnerLoaderComponent implements OnInit {

  @Input() visible: boolean;   

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }
}
