import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { I18n } from 'src/app/i18n';
import { DataAnalyticsService } from 'src/app/services/data-analytics.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { DailyProductionDTO } from 'src/app/models/DailyProductionDTO';

const borderColor = 'rgb(255, 99, 132)';
const backgroundColor = 'rgb(111,168,220)';
const typeIN = "IN";
const typeOUT = "OUT";

@Component({
  selector: 'app-daily-production-card',
  templateUrl: './daily-production-card.component.html',
  styleUrls: ['./daily-production-card.component.scss']
})
export class DailyProductionCardComponent implements OnInit {

  constructor(
    private router: Router,
    private service: DataAnalyticsService,
    private t: I18n
  ) { }

  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    layout: {
      padding: {
        left: 20,
        right: 30
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 12,
        }
      }
    },
    scales: {
      xAxes: [{
        stacked: true,
        display: false,
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        stacked: true,
        display: true,
        gridLines: {
          display: true
        }
      }]
    },
    maintainAspectRatio: false,
  };

  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = false;
  public barChartData: ChartDataSets[] = [];
  public barChartDataIN: ChartDataSets[] = [];
  public barChartDataOut: ChartDataSets[] = [];
  public barChartLabels: string[] = []; //Devem ser listados como label todos os status que tenham pelo menos um pedido
  public barChartPlugins = [pluginDataLabels];
  public dailyProduction: DailyProductionDTO[] = [];
  public type = "";

  ngOnInit() {
    this.barChartLabels = ['SURFACAGEM', 'MONTAGEM', 'ESTOQUE', 'ATENDIMENTO', 'SPIN', 'COLORACAO', 'HMC', 'SALA DE ARMACOES'];
    this.barChartData = []
    this.barChartDataIN = []
    this.barChartDataOut = []
    this.getBarChartData();
  }

  public getBarChartData() {
    this.service.getDailyProduction(typeIN).then(data => {
      this.barChartLabels = [];
      this.dailyProduction = data;
      for (let i = 0; i < this.dailyProduction.length; i++) {
        this.barChartLabels.push(this.dailyProduction[i].sectorName);
      }
      this.barChartDataIN = [{
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        data: []
      }];
      for (let i = 0; i < this.dailyProduction.length; i++) {
        this.barChartDataIN[0].data.push(this.dailyProduction[i].production);
      }
      this.clickIn();
    });

    this.service.getDailyProduction(typeOUT).then(data => {
      this.barChartLabels = [];
      this.dailyProduction = data;
      for (let i = 0; i < this.dailyProduction.length; i++) {
        this.barChartLabels.push(this.dailyProduction[i].sectorName);
      }
      this.barChartDataOut = [{
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        data: []
      }];
      for (let i = 0; i < this.dailyProduction.length; i++) {
        this.barChartDataOut[0].data.push(this.dailyProduction[i].production);
      }
    }); 
  }

  public clickIn() {
    this.barChartData = this.barChartDataIN;
    this.type = typeIN;
  }

  public clickOut() {
    this.barChartData = this.barChartDataOut;
    this.type = typeOUT;
  }

}
