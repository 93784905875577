import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { I18n } from './i18n';
import './utils/string';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainComponent } from './pages/private/main/main.component';
import { HomeComponent } from './pages/private/home/home.component';
import { LeftMenuOrderComponent } from './components/left-menu-order/left-menu-order.component';
import { OrderErrorIntegrationComponent } from './components/order-error-integration/order-error-integration.component';
import { OrderDashboardComponent } from './pages/private/order-dashboard/order-dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FormBuilder, FormControl } from '@angular/forms';
import { QueryBuilderConfig, QueryBuilderClassNames, QueryBuilderModule } from "angular2-query-builder";
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { StorageHandler } from './handlers/storage-handler';
import { SpinnerLoaderComponent } from './components/shared/spinner-loader/spinner-loader.component';
import { ConfigurationComponent } from './pages/private/configuration/configuration.component';
import { LeftMenuConfigurationComponent } from './components/left-menu-configuration/left-menu-configuration.component';
import { ConfigurationListComponent } from './components/configuration-list/configuration-list.component';
import { EditConfigurationComponent } from './pages/private/edit-configuration/edit-configuration.component';
import { OrderErrorCalculationComponent } from './components/order-error-calculation/order-error-calculation.component';
import { ManualCalculationComponent } from './components/manual-calculation/manual-calculation.component';
import { OrderService } from './services/order-service';
import { ServiceHandler } from './handlers/service.handler';
import { ModalComponent } from './components/shared/modal/modal.component';
import { QueryBuilderService } from './services/query-builder-service';
import { NavigationComponent } from './pages/private/navigation/navigation.component';
import { ToastsComponent } from './components/shared/toasts/toasts.component';
import { TransformTypePipe } from './pipe/transform-type.pipe';
import { PaginatorComponent } from './components/shared/paginator/paginator.component';
import { LaboratoryComponent } from './pages/private/laboratory/laboratory.component';
import { ListComponent } from './components/shared/list/list.component';
import { ModalProducaoComponent } from './components/shared/modal-producao/modal-producao.component';
import { CellLaboratoryService } from './services/cellLaboratory-service';
import { FilterAssociatedComponent } from './components/filter-associated/filter-associated.component';
import { ActivedPipe } from './pipe/actived.pipe';
import { registerLocaleData } from '@angular/common';
import { VisualizarPedidosComponent } from './components/visualizar-pedidos/visualizar-pedidos.component';
import { PedidosCelulaPipe } from './pipe/pedidos-celula.pipe';
import localePT from'@angular/common/locales/pt';
import localeEN from'@angular/common/locales/en';
import { ManagerialConfigurationComponent } from './pages/private/managerial-configuration/managerial-configuration.component';
import { RequestComponent } from './pages/private/request/request.component';
import { StatusManagementService } from './services/status-management-service';
import { DirectionPipe } from './pipe/direction.pipe';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { StatusPipe } from './pipe/status.pipe';
import { ErrorCertificateComponent } from './components/error-certificate/error-certificate.component';
import { OrdersSearchComponent } from './pages/private/orders-search/orders-search.component';
import { LateOrdersOutDetailComponent } from './pages/private/late-orders-out-detail/late-orders-out-detail.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { DataAnalyticsService } from './services/data-analytics.service';
import { VolumeWipCardComponent } from './pages/private/home/components/volume-wip-card/volume-wip-card.component';
import { ServiceRateOutCardComponent } from './pages/private/home/components/service-rate-out-card/service-rate-out-card.component';
import { LateOrdersOutCardComponent } from './pages/private/home/components/late-orders-out-card/late-orders-out-card.component';
import { AboveAverageOrdersCardComponent } from './pages/private/home/components/above-average-orders-card/above-average-orders-card.component';
import { WeeklyServiceRateCardComponent } from './pages/private/home/components/weekly-service-rate-card/weekly-service-rate-card.component';
import { VolumeWipDetailComponent } from './pages/private/volume-wip-detail/volume-wip-detail.component';
import { AboveAverageOrdersDetailComponent } from './pages/private/above-average-orders-detail/above-average-orders-detail.component';
import { ServiceRateOutDetailComponent } from './pages/private/service-rate-out-detail/service-rate-out-detail.component';
import { WeeklyServiceRateDetailComponent } from './pages/private/weekly-service-rate-detail/weekly-service-rate-detail.component';
import { WeeklyVolumeCardComponent } from './pages/private/home/components/weekly-volume-card/weekly-volume-card.component';
import { WeeklyVolumeDetailComponent } from './pages/private/weekly-volume-detail/weekly-volume-detail.component';
import { TopBreaksCardComponent } from './pages/private/home/components/top-breaks-card/top-breaks-card.component';
import { TopBreaksDetailComponent } from './pages/private/top-breaks-detail/top-breaks-detail.component';
import { ProductionCardComponent } from './pages/private/home/components/production-card/production-card.component';
import { ReworkDetailComponent } from './pages/private/rework-detail/rework-detail.component';
import { ReworkComponent } from './pages/private/home/components/rework/rework.component';
import { ModalFeasibilityComponent } from './components/shared/modal-feasibility/modal-feasibility.component';
import { CertificateComponent } from './pages/private/certificate/certificate.component';
import { AboveAverageOrdersByStatusComponent } from './pages/private/above-average-orders-by-status/above-average-orders-by-status.component';
import { ReleaseOrderComponent } from './pages/private/release-order/release-order.component';
import { ReportBreaksComponent } from './pages/private/reports/report-breaks/report-breaks.component';
import { ReportService } from './services/report-service';
import { ExtractionsComponent } from './pages/private/reports/extractions/extractions.component';
import { DailyProductionCardComponent } from './pages/private/home/components/daily-production-card/daily-production-card.component';
import { DailyProductionDetailComponent } from './pages/private/daily-production-detail/daily-production-detail.component';
import { DashboardComponent } from './pages/private/home/components/dashboard/dashboard.component';
registerLocaleData(localePT);
registerLocaleData(localeEN);

export function kcInitializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init(environment.keycloakOptions);
        //console.log('Keycloak is initialized');
        resolve();
      } catch (error) {
        console.log('Error thrown in init ' + error);
        reject(error);
      }
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HomeComponent,
    DashboardComponent,
    LeftMenuOrderComponent,
    OrderErrorIntegrationComponent,
    OrderDashboardComponent,
    SpinnerLoaderComponent,
    ConfigurationComponent,
    LeftMenuConfigurationComponent,
    ConfigurationListComponent,
    EditConfigurationComponent,
    OrderErrorCalculationComponent,
    ManualCalculationComponent,
    ModalComponent,
    NavigationComponent,
    ToastsComponent,
    TransformTypePipe,
    PaginatorComponent,
    LaboratoryComponent,
    ListComponent,
    ModalProducaoComponent,
    FilterAssociatedComponent,
    ActivedPipe,
    VisualizarPedidosComponent,
    PedidosCelulaPipe,
    ManagerialConfigurationComponent,
    RequestComponent,
    DirectionPipe,
    StatusPipe,
    ErrorCertificateComponent,
    OrdersSearchComponent,
    LateOrdersOutDetailComponent,
    VolumeWipCardComponent,
    ServiceRateOutCardComponent,
    LateOrdersOutCardComponent,
    AboveAverageOrdersCardComponent,
    WeeklyServiceRateCardComponent,
    VolumeWipDetailComponent,
    AboveAverageOrdersDetailComponent,
    ServiceRateOutDetailComponent,
    WeeklyServiceRateDetailComponent,
    WeeklyVolumeCardComponent,
    WeeklyVolumeDetailComponent,
    TopBreaksCardComponent,
    TopBreaksDetailComponent,
    ProductionCardComponent,
    ReworkDetailComponent,
    ReworkComponent,
    ModalFeasibilityComponent,
    CertificateComponent,
    AboveAverageOrdersByStatusComponent,
    ReleaseOrderComponent,
    ReportBreaksComponent,
    ExtractionsComponent,
    DailyProductionCardComponent,
    DailyProductionDetailComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    QueryBuilderModule,
    MatDialogModule,
    KeycloakAngularModule,
    ChartsModule
  ],
  providers: [
    I18n,
    StorageHandler,
    ServiceHandler,
    OrderService,
    ReportService,
    QueryBuilderService,
    CellLaboratoryService,
    StatusManagementService,
    ThemeService,
    DataAnalyticsService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: 'custom-modalbox', autoFocus: false } },
    { provide:LOCALE_ID, useValue:'pt'},
    { provide:LOCALE_ID, useValue:'en'},
    { provide: APP_INITIALIZER, useFactory: kcInitializer, multi: true, deps: [KeycloakService] }
  ],
  entryComponents: [
    ModalComponent,
    ModalProducaoComponent,
    ModalFeasibilityComponent,
    ToastsComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
