import { Injectable } from '@angular/core';
import { ApiRoute } from '../enums/api-route';
import { ServiceHandler } from '../handlers/service.handler';
import { AboveAverageStatusDetailByStatusDTO } from '../models/AboveAverageStatusDetailByStatusDTO';
import { AboveAverageStatusDetailDTO } from '../models/AboveAverageStatusDetailDTO';
import { AboveAverageStatusDTO } from '../models/AboveAverageStatusDTO';
import { BrokenStatusDTO } from '../models/BrokenStatusDTO';
import { DailyProductionByTimeDTO } from '../models/DailyProductionByTimeDTO';
import { DailyProductionDTO } from '../models/DailyProductionDTO';
import { LateOrderByPeriod } from '../models/LateOrderByPeriod';
import { LateOrderByPeriodDetail } from '../models/LateOrderByPeriodDetail';
import { LateOrderByPeriodDetailSector } from '../models/LateOrderByPeriodDetailSector';
import { OrderDTO } from '../models/OrderDTO';
import { OrderLenseQuantityDTO } from '../models/OrderLenseQuantityDTO';
import { OrderServiceRateStatusDTO } from '../models/OrderServiceRateStatusDTO';
import { OrderTrackingDTO } from '../models/OrderTrackingDTO';
import { ReworkStatusDTO } from '../models/ReworkStatusDTO';
import { ServiceRateDetailedDTO } from '../models/ServiceRateDetailedDTO';
import { ServiceRateDTO } from '../models/ServiceRateDTO';
import { ServiceRateWeeklyDetailedDTO } from '../models/ServiceRateWeeklyDetailedDTO';
import { ServiceRateWeeklyDTO } from '../models/ServiceRateWeeklyDTO';
import { VolumeDataDTO } from '../models/VolumeDataDTO';

@Injectable()
export class DataAnalyticsService {

  constructor(
    public service: ServiceHandler
  ) { }
  
  listLateOrders(orderLenseType: string): Promise<LateOrderByPeriod> {
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listLateOrders.replace('{orderLenseType}', orderLenseType)).then(data => {
          resolve(data);
      }).catch(err => {
          reject(err);
      });
    });
  }

  listLateOrdersSectors(orderLenseType: string): Promise<LateOrderByPeriodDetail[]> {
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listLateOrdersSectors.replace('{orderLenseType}', orderLenseType)).then(data => {
          resolve(data);
      }).catch(err => {
          reject(err);
      });
    });
  }

  listLateOrdersStatusWithNoSector(orderLenseType: string): Promise<LateOrderByPeriodDetail[]> {
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listLateOrdersStatusWithNoSector.replace('{orderLenseType}', orderLenseType)).then(data => {
          resolve(data);
      }).catch(err => {
          reject(err);
      });
    });
  }

  listLateOrdersStatusWithSector(orderLenseType: string, sector: string): Promise<LateOrderByPeriodDetail[]> {
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listLateOrdersStatusWithSector.replace('{orderLenseType}', orderLenseType)
                                                                            .replace('{sector}', sector)).then(data => {
          resolve(data);
      }).catch(err => {
          reject(err);
      });
    });
  }

  listLateOrdersBySectorAndStatus(orderLenseType: string, sector: string, mngtStatus: string, days: string): Promise<OrderDTO[]> {
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listLateOrdersBySectorAndStatus.replace('{orderLenseType}', orderLenseType)
                                                                            .replace('{sector}', sector)
                                                                            .replace('{mngtStatus}', mngtStatus)
                                                                            .replace('{days}', days)).then(data => {
          resolve(data);
      }).catch(err => {
          reject(err);
      });
    });
  }

  listLateOrdersByNoSectorAndStatus(orderLenseType: string, sector: string, mngtStatus: string, days: string): Promise<OrderDTO[]> {
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listLateOrdersByNoSectorAndStatus.replace('{orderLenseType}', orderLenseType)
                                                                            .replace('{sector}', sector)
                                                                            .replace('{mngtStatus}', mngtStatus)
                                                                            .replace('{days}', days)).then(data => {
          resolve(data);
      }).catch(err => {
          reject(err);
      });
    });
  }

  listServiceRateStatus(): Promise<OrderServiceRateStatusDTO[]> {
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listServiceRateStatus).then(data => {
          resolve(data);
      }).catch(err => {
          reject(err);
      });
    });
  }

  listLenseQuantityByType(orderLenseType: string): Promise<OrderLenseQuantityDTO[]> {
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listLenseQuantityByType.replace('{orderLenseType}', orderLenseType)).then(data => {
          resolve(data);
      }).catch(err => {
          reject(err);
      });
    });
  }
  
  listVolumeData(startDate: string, endDate: string): Promise<VolumeDataDTO>{

    const volumeDataDTO: VolumeDataDTO = new VolumeDataDTO();

    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listVolumeData.replace('{startDate}', startDate).replace('{endDate}', endDate)).then(data => {
        resolve(data);
      }).catch(err => {
          reject(err);
      });
    });
  }

    
  listVolumeDataWeekly(): Promise<VolumeDataDTO>{

    const volumeDataDTO: VolumeDataDTO = new VolumeDataDTO();

    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listVolumeDataWeekly).then(data => {
        resolve(data);
      }).catch(err => {
          reject(err);
      });
    });
  }

  getServiceRate(serviceRateLenseType: string): Promise<ServiceRateDTO>{
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listServiceRate.replace('{serviceRateLenseType}', serviceRateLenseType)).then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
    });
    })
  }

  getDetailedServiceRate(serviceRateLenseType: string): Promise<ServiceRateDetailedDTO>{
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listDetailedServiceRate.replace('{serviceRateLenseType}', serviceRateLenseType)).then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
    });
    })
  }

  getServiceRateWeekly(): Promise<ServiceRateWeeklyDTO>{
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listServiceRateWeekly).then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
    });
    })
  }

  getDetailedServiceRateWeekly(startDate: string, endDate: string, client: string): Promise<ServiceRateWeeklyDetailedDTO>{
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listDetailedServiceRateWeekly.replace('{startDate}', startDate)
                                                                          .replace('{endDate}', endDate)
                                                                          .replace('{client}', client)).then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
    });
    })
  }

  getBrokenStatus(): Promise<BrokenStatusDTO[]>{
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listBrokenStatus).then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
    });
    })
  }

  getDetailedBrokenStatus(): Promise<BrokenStatusDTO[]>{
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listDetailedBrokenStatus).then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
    });
    })
  }

  getReworkStatus(): Promise<ReworkStatusDTO[]>{
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listReworkStatus).then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
      });
    })
  }

  getDetailedReworkStatus(): Promise<ReworkStatusDTO[]>{
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listDetailedReworkStatus).then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
      });
    })
  }

  getAboveAverageData(): Promise<AboveAverageStatusDTO[]>{
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listAboveAverageStatus).then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
      });
    })
  }

  getDetailedAboveAverageData(): Promise<AboveAverageStatusDetailByStatusDTO>{
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listDetailedAboveAverageStatus).then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
      });
    })
  }
  getAboveAverageStatusOrders(orderStatus: string, selectedFilters: string): Promise<OrderTrackingDTO>{
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listAboveAverageStatusOrders
        .replace('{orderStatus}', orderStatus).replace('{selectedFilters}', selectedFilters))
      .then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
      });
    })
  }  

  generatePdfFile(erpJob): Promise<OrderDTO[]>{
    return new Promise((resolve, reject) => {
        this.service.Get(ApiRoute.order.generatePdfFile
            .replace('{erpJob}', erpJob)).then(data => {
            resolve(data);
        }).catch(err => {
            reject(err);
        });
    });
}

  getPdfFile(erpJob): Promise<Blob>{
      return new Promise((resolve, reject) => {
          this.service.GetPDF(ApiRoute.order.getPdfFile
              .replace('{erpJob}', erpJob)).then(data => {
                  var byteArray = new Uint8Array(data.binFileImage);
                  var blob = new Blob([byteArray], { type: 'application/pdf' });
              resolve(data);
          }).catch(err => {
              console.log('->')
              reject(err);
          });
      });
  }

  getDailyProduction(orderLenseType: string): Promise<DailyProductionDTO[]>{
      return new Promise((resolve, reject) => {
        this.service.Get(ApiRoute.dataAnalytics.listDailyProduction
                        .replace('{orderLenseType}', orderLenseType))
                        .then(data => {
            resolve(data);
        }).catch(err => {
            reject(err);
        });
    });
  }

  getDailyProductionByHour(startDate: string, endDate: string, sector: string): Promise<DailyProductionByTimeDTO>{
    return new Promise((resolve, reject) => {
      this.service.Get(ApiRoute.dataAnalytics.listDailyProductionByHour
                      .replace('{startDate}', startDate)
                      .replace('{endDate}', endDate)
                      .replace('{sector}', sector))
                      .then(data => {
          resolve(data);
      }).catch(err => {
          reject(err);
      });
    });
  }

}